import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ServicePartnerService {

  constructor(private http: HttpService, private localStorage: LocalStorageService) { }

  private AddCustomerModal = new Subject<any>();
  private AddSubServicePartnerModal = new Subject<any>();
  private viewSpModal = new Subject<any>();
  private EditCustomerModal = new Subject<any>();
  private EditSubServPartnerModal = new Subject<any>();
  private DeactivateCustomerModel = new Subject<any>();
  private DeleteSubServicePartnerModel = new Subject<any>();
  private ResetSubServicePartnerModel = new Subject<any>();
  private ViewAllCustomer = new Subject<any>();
  private AddMoreGstin = new Subject<any>();
  backtosummary = new Subject<any>();
  backtosummarylast = new Subject<any>();
  

  //add Customer
  setAddCustomer(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for add customer--", status)
    this.AddCustomerModal.next({ status });
  }
  getCustomer(): Observable<any> {
    return this.AddCustomerModal.asObservable();
  }
  //addSubServicePartner
  setSubServicePartner(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for subservice partner--", status)
    this.AddSubServicePartnerModal.next({ status });
  }
  getSubServicePartner(): Observable<any> {
    return this.AddSubServicePartnerModal.asObservable();
  }
  setViewSpModal(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for view--", status)
    this.viewSpModal.next({ status });

  }
  getViewSpModal(): Observable<any> {
    return this.viewSpModal.asObservable();
  }

  /**@method: For edit customer open modal */
  setEditCustomer(status: boolean) {
    console.log("SetEditCustomer--", status)
    this.EditCustomerModal.next({ status });
  }
  getEditCustomer(): Observable<any> {
    return this.EditCustomerModal.asObservable();
  }

  /**@method: For edit sub service partner open modal */
  setEditSubServPartner(status: boolean) {
    console.log("SetEditCustomer--", status)
    this.EditSubServPartnerModal.next({ status });
  }
  getEditSubServPartner(): Observable<any> {
    return this.EditSubServPartnerModal.asObservable();
  }

  //deactivate customer
  setDeactivateCustomer(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for add--", status)
    this.DeactivateCustomerModel.next({ status });
  }
  getDeactivateCustomer(): Observable<any> {
    return this.DeactivateCustomerModel.asObservable();
  }

  //delete sub service partner
  setDeleteSubServicePartner(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for add--", status)
    this.DeleteSubServicePartnerModel.next({ status });
  }
  getDeleteSubServicePartner(): Observable<any> {
    return this.DeleteSubServicePartnerModel.asObservable();
  }

  //reset sub service partner
  setResetSubServicePartner(status: boolean) {
    console.log("ServicePartnerService got status from dashboard for add--", status)
    this.ResetSubServicePartnerModel.next({ status });
  }
  getResetSubServicePartner(): Observable<any> {
    return this.ResetSubServicePartnerModel.asObservable();
  }
  //view all customer
  setViewAllCustomer(status: boolean) {
    console.log("ServicePartnerService got status from dashboard to view All Customer--", status)
    this.ViewAllCustomer.next({ status });
  }
  getViewOfAllCustomer(): Observable<any> {
    return this.ViewAllCustomer.asObservable();
  }
  setMoreGstin(status: boolean) {
    console.log("ServicePartnerService got status from dashboard to Add more Gstin--", status)
    this.AddMoreGstin.next({ status });
  }
  getMoreGstin(): Observable<any> {
    return this.AddMoreGstin.asObservable();
  }

  //******************* httpService methods **************************

  addCustomer(url, model): Observable<any> {
    console.log("url model-----", url)
    console.log("model model-----", model)
    return this.http.postService(url, model);
  }
  viewAllCustomer(url): Observable<any> {

    return this.http.getService(url);
  }
  viewCustomer(url): Observable<any> {
    console.log("view customer url>>-->>", url);
    return this.http.getService(url);
  }
  updateCustomer(url, model): Observable<any> {
    return this.http.putService(url, model);
  }

  viewSubsidiaryGstin(url): Observable<any> {
    console.log("view customer url>>-->>", url);
    return this.http.getService(url);
  }

  viewSubsidiaryBranchGstin(subsidiaryGstin): Observable<any> {
    console.log("view customer url>>-->>", url);
    var url = "getAllHeadQuartrBranchesGstin?&hqGstin=" + subsidiaryGstin;
    return this.http.getService(url);
  }

  checkGstin(url, model): Observable<any> {
    return this.http.postService(url, model);
  }

  checkGstinGov(url): Observable<any> {
    return this.http.getServicewithheader(url);
  }
  gstinDetails(url, model): Observable<any> {
    return this.http.postService(url, model);
  }

  getAllCompany(companyID): Observable<any> {

    var url = "getAllCompanyName?&tgdsLoginId=" + companyID;
    return this.http.getService(url);

  }

  getAllCompanyGstin(companyName): Observable<any> {

    var url = "getAllCompanyGstin?&companyName=" + companyName;
    return this.http.getService(url);

  }

  getAllHeadQuarterBranch(hqGstin): Observable<any> {

    var url = "getAllHeadQuartrBranchesGstin?&hqGstin=" + hqGstin;
    return this.http.getService(url);
  }

  addMoreGstin(url, model): Observable<any> {
    return this.http.postService(url, model);
  }

  getCAList(): Observable<any> {
    console.log(this.localStorage.getLocalStorage("ID"))
    var url = "getCAlist?&companyID=" + this.localStorage.getLocalStorage("ID");
    return this.http.getService(url);
  }
  //get List Of SP-CA-Refferal
  getListOfSpCaRefferal(url): Observable<any> {
    return this.http.getService(url);
  }
  //get Tree View
  viewTreeofCompany(url): Observable<any> {
    return this.http.getService(url);
  }

  getReferralList(): Observable<any> {

    var url = "getReferrallist?&companyID=" + this.localStorage.getLocalStorage("ID");
    return this.http.getService(url);
  }

  //get List Of Users
  getListOfUsers(url): Observable<any> {
    return this.http.getService(url);
  }
  //CFO Dashboard API
  getData(url): Observable<any> {
    return this.http.getService(url);
  }

  getTurnOver(url): Observable<any> {
    return this.http.getService(url);
  }
  getGstinsOfCompany(url): Observable<any> {
    return this.http.getService(url);
  }
  getITC(url): Observable<any> {
    return this.http.getService(url);
  }
  getLiabilityItcService(url): Observable<any> {
    return this.http.getService(url);
  }
  getGstPayableAdbService(url){
    return this.http.getService(url);
  }
  getEfilingReport(url): Observable<any> {
    return this.http.getService(url);
  }
  getSubSidiaryService(url):Observable<any> {
    return this.http.getService(url);
  }
  getgstr13b(url) {
    return this.http.getService(url);
  }
  getGstinListOfSubsidiary(url) {
    return this.http.getService(url);
  }
  setBacktoSummary(status: boolean) {
    this.backtosummary.next(status);
  }

  getBacktoSummary(): Observable<any> {
    return this.backtosummary.asObservable();
  }

  setBacktoSummarylast(status: boolean) {
    this.backtosummarylast.next(status);
  }

  getBacktoSummarylast(): Observable<any> {
    return this.backtosummarylast.asObservable();
  }
  getChekListQuestionCategory(url){
    return this.http.getService(url);
  }
  getQuestions(url){
    return this.http.getService(url);
  }
  getItcReportService(url){
    return this.http.getService(url);
  }


  currencyFormatter (value) {
    let ret;
    let isNumberNegative = 0;
    if(value < 0){
      isNumberNegative =1 
      value *= (-1)
    }
    if (value / 1000 <= 10) {
        ret =value / 1000 + 'k';
    } else  if (value / 100000 < 100) {
      ret= value / 100000 + 'L';
  } else {
    ret= value / 10000000 + 'Cr';
}
return (isNumberNegative ==1)?"-" + ret:ret;
}
}
