import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/auth/login.service';
import { LocalStorageService } from '../service/local-storage.service';

@Component({
  selector: 'app-coming-soon-tab',
  templateUrl: './coming-soon-tab.component.html',
  styleUrls: ['./coming-soon-tab.component.css']
})
export class ComingSoonTabComponent implements OnInit {


  heading: any;
  video = true;
  PDF = false;
  TryThis = false;

  src = "../../assets/PDF/sample.pdf";

  constructor(private loginservice: LoginService, private localStorageObj: LocalStorageService) {

    this.loginservice.setLoginResponse(false);

    try {
      var einvoice = this.localStorageObj.getLocalStorage('commingsoon');
      if (einvoice == einvoice) {
        this.heading = einvoice;
      }
    } catch (error) {
      error;
    }
  }

  tab(event) {

    if (event == 'video'){
      this.video = true;
      this.PDF = false;
      this.TryThis = false;
    } else if (event == 'PDF') {
      this.PDF = true;
      this.video = false;
      this.TryThis = false;
    } else if (event == 'TryThis') {
      this.TryThis = true;
      this.video = false;
      this.PDF = false;
    }
  }

  ngOnInit() {
  }

}
