import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ɵConsole } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../service/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalStorageService } from '../service/local-storage.service';
import { ApiService } from '../service/http/api.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('canvasEl') public canvasEl: ElementRef;

  displaypopup: boolean = false;

  loginForm: FormGroup;
  registrationForm: FormGroup;
  OTPValidation: FormGroup;
  captchaNo: any;
  captchaId: any;
  formBotton = true;

  errormess = true;
  myGstin: any;
  myMobile: any;
  myEmail: any;
  Email_ID: any;

  login = false;
  registration = true;

  timeLeft: number = 300;
  interval;
  openModel = false;
  warningMessage: boolean = false;
  uerEmailId: any;
  private context: CanvasRenderingContext2D;
  loginDetails: any;
  secreteKey = "Sh*&54r";
  constructor(private loginService: LoginService, private toastrService: ToastrService, private route: Router,
    private localStorage: LocalStorageService, private api: ApiService,) {

    document.getElementsByTagName("body")[0].setAttribute("id", "loginbody");

    function validateInput(c: FormControl) {

      let GSTINFORMAT_REGEX = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9a-zA-Z]{1}/;
      let GSTIN_UNBODY_FORMAT_REGEX = /^[0-9]{4}[a-zA-Z]{3}[0-9]{5}[UuOo]{1}[N][a-zA-Z0-9]{1}/;
      let GSTIN_GOVT_DEPTID_FORMAT_REGEX = /^[0-9]{2}[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}[0-9]{1}[Zz]{1}[0-9]{1}/;
      let GSTIN_NRI_ID_FORMAT_REGEX = /^[0-9]{4}[a-zA-Z]{3}[0-9]{5}[Nn][Rr][0-9a-zA-Z]{1}/;
      let GSTIN_TDS_FORMAT_REGEX = /^[0-9]{2}[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Dd]{1}[0-9a-zA-Z]{1}/;
      let GSTIN_TCS_FORMAT_REGEX = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Cc]{1}[0-9a-zA-Z]{1}/;
      let GSTIN_OIDAR_FORMAT_REGEX = /^[9][9][0-9]{2}[a-zA-Z]{3}[0-9]{5}[Oo][Ss][0-9a-zA-Z]{1}/;
      let blank = /^\s*$/;
      return (GSTINFORMAT_REGEX.test(c.value) || GSTIN_UNBODY_FORMAT_REGEX.test(c.value)
        || GSTIN_GOVT_DEPTID_FORMAT_REGEX.test(c.value) || GSTIN_NRI_ID_FORMAT_REGEX.test(c.value)
        || GSTIN_TDS_FORMAT_REGEX.test(c.value) || GSTIN_TCS_FORMAT_REGEX.test(c.value)
        || GSTIN_OIDAR_FORMAT_REGEX.test(c.value) || blank.test(c.value)) ? null : {
        validateInput: {
          valid: false
        }
      };
    }

    const authUser: any = this.localStorage.getLocalStorage('ID');
    if (authUser != null) {
      this.route.navigate(['/mainlogin'], { skipLocationChange: true });
    }
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
      captcha: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
    });

    this.registrationForm = new FormGroup({
      gstin: new FormControl({ value: '', disabled: false },
        validateInput),
      mobile: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]*$')]),
      email: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.email]))
    });

    this.OTPValidation = new FormGroup({
      OTPtxt: new FormControl('', Validators.required),
    });
    toastrService.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit() {
    this.checkCaptcha();
    this.startTimer();
    this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
  }

  startTimer() {

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 300;
        if (this.route.url === '/login') { this.checkCaptcha(); }
      }
    }, 1000);
  }

  checkCaptcha() {
    this.captchaNo = null;
    this.captchaId = null;

    this.loginService.callCaptcha().subscribe(data => {

      this.context.font = '40px monospace ';
      this.context.textBaseline = 'middle';
      this.context.textAlign = 'center';
      const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width / 2;
      const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height / 2;
      this.context.clearRect(0, 0, x * 2, y * 2);
      this.captchaNo = data.captchaNo;
      this.captchaId = data.captchaID;
      this.loginForm.controls['captcha'].setValue('');
      this.context.fillText(this.captchaNo, x, y);
    });
    this.errormess = true;
  }

  cap: any = [];
  verCaptcha: any = [];
  verifyCaptcha(e) {
    this.verCaptcha = e.target.value;
    this.cap = this.captchaNo;
    var acap = this.verCaptcha;
    for (let i = 0; i <= 5; i++) {
      try {
        if (this.verCaptcha[0] == this.cap[0]) {
          if (this.cap.includes(acap)) {
            if (this.verCaptcha.length === 6) {
              if (this.verCaptcha.toString().toUpperCase() === this.captchaNo.toString().toUpperCase()) {
                this.errormess = true;
              }
              else if (this.verCaptcha.toString().toUpperCase() !== this.captchaNo.toString().toUpperCase()) {
                this.errormess = true;
              }
            }
            else {
              this.errormess = true;
              this.loginForm.get('captcha').setErrors({
                notUnique: true
              });
            }

          }
          else {
            this.errormess = false;
            this.loginForm.get('captcha').setErrors({
              notUnique: true
            });
          }
        }
        else if (this.verCaptcha == '') {
          this.errormess = true;
          this.loginForm.get('captcha').setErrors({
            notUnique: true
          });
        }
        else {
          this.errormess = false;
          this.loginForm.get('captcha').setErrors({
            notUnique: true
          });
        }

      }
      catch (error) {
      }
    }
  }

  clickRegistration() {
    this.login = true;
    this.registration = false;
    this.loginForm.reset();
    this.registrationForm.reset();
  }


  clickLoginOnRegistrationPage() {
    this.registration = true;
    this.login = false;
    this.loginForm.reset();
    this.registrationForm.reset();
  }

  forgotPassword() {
    this.route.navigate(['/resetpasswordpage']);
    this.login = true;
    this.loginForm.reset();
    this.registrationForm.reset();

  }

  checkGstin(event) {
    var gstin = event.target.value;
    var checkValidGstin: boolean;
    let regexp = new RegExp(/^(([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9a-zA-Z]{1}))$/);
    checkValidGstin = regexp.test(gstin);
    if (gstin.length === 15) {
      if (checkValidGstin) {
        this.loginService.checkGstin(gstin)
          .subscribe(data => {
            if (data.Reason === 'gstin_found') {
              this.toastrService.error('This GSTIN Already Registered.');
              this.registrationForm.patchValue({ 'gstin': '' });
            } else {

            }
          });

      } else {
        this.toastrService.error('Invalid GSTIN Number');
      }
    } else {
      // this.toastrService.error('Invalid GSTIN Number' );
    }
  }


  checkMobile(event) {
    var number = event.target.value;
    if (event.target.value.length === 10) {

      this.loginService.checkMobile(number)
        .subscribe(data => {

          if (data.Reason == 'mobile_number_found') {
            this.toastrService.error('This Mobile Number Already Registered.');
            this.registrationForm.patchValue({ 'mobile': '' });
          }
          else {

          }

        })

    }
  }



  checkEmail() {
    var checkValidEmail: boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    checkValidEmail = regexp.test(this.myEmail);
    if (checkValidEmail) {
      this.loginService.checkEmail(this.myEmail)
        .subscribe(data => {
          if (data.Reason == 'email_id_found') {
            this.toastrService.error('This Email ID Already Registered.');
            this.registrationForm.patchValue({ 'email': '' });
          }
        })
    }
    else {
      this.toastrService.error('Invalid Email ID');
    }
  }
  encrypt(key, value) {
    key = CryptoJS.enc.Utf8.parse(key);
    let ciphertext = CryptoJS.AES.encrypt(value, key, { iv: key }).toString();
    return ciphertext;
  }

  decrypt(key, value) {
    key = CryptoJS.enc.Utf8.parse(key);
    let decryptedData = CryptoJS.AES.decrypt(value, key, {
      iv: key
    });
    return decryptedData.toString(CryptoJS.enc.Utf8);
  }

  getStatus() {
    this.loginDetails = {
      'username': CryptoJS.AES.encrypt(this.username, this.secreteKey) + "",
      'password': CryptoJS.AES.encrypt(this.password, this.secreteKey) + "",
      'captcha': CryptoJS.AES.encrypt(this.captcha, this.secreteKey) + "",
      'captchId': CryptoJS.AES.encrypt(this.captchaId, this.secreteKey) + "",
      'loginFrom': CryptoJS.AES.encrypt("eFilling", this.secreteKey) + "",
      'isContinue': CryptoJS.AES.encrypt("Yes", this.secreteKey) + ""
    };

    this.loginService.newLogin(this.loginDetails)
      .subscribe(data => {

        try {
          this.spEmailId = data.Details[0].emailID;
          this.spcontactPersonName = data.Details[0].contactPersonName;
          this.spcontactPersonNumber = data.Details[0].contactPersonNumber;

          /* localStorage.setItem("spEmailId", this.spEmailId);
          localStorage.setItem("spcontactPersonName", this.spcontactPersonName);
          localStorage.setItem("spcontactPersonNumber", this.spcontactPersonNumber); */

          this.localStorage.createLocalStorage("spEmailId", this.spEmailId);
          this.localStorage.createLocalStorage("spcontactPersonName", this.spcontactPersonName);
          this.localStorage.createLocalStorage("spcontactPersonNumber", this.spcontactPersonNumber);

        } catch (error) {

          /* localStorage.setItem("spEmailId", '');
             localStorage.setItem("spcontactPersonName", '');
             localStorage.setItem("spcontactPersonNumber", ''); */


          this.localStorage.createLocalStorage("spEmailId", '');
          this.localStorage.createLocalStorage("spcontactPersonName", '');
          this.localStorage.createLocalStorage("spcontactPersonNumber", '');
        }

        if (data.Status === 'Warning') {
          this.displaypopup = true;
        }
        else if (data.Status === 'Success') {

          try {
            this.localStorage.createLocalStorage("ChangedPasswordSecurity", " " + data.passMsg);
          } catch (error) {
            this.localStorage.createLocalStorage("ChangedPasswordSecurity", '');
          }

          this.localStorage.createLocalStorage('sessionKey', data.Details[0].sessionKey); //used to main user session
          this.localStorage.createLocalStorage('empID', JSON.stringify(data.Details[0].userID));
          this.localStorage.createLocalStorage("ID", JSON.stringify(data.Details[0].companyID));//For Dev

          if (data.Status === 'Success' && data.Details[0].status === 1
            && data.Details[0].emailStatus === 1 || data.Details[0].emailStatus === 0) {

            //      this.localStorage.createLocalStorage("sessionKey", JSON.stringify(data.Details[0].sessionKey)); //For Dev
            this.localStorage.createLocalStorage('token', data.token);
            this.localStorage.createLocalStorage('orgType', data.Details[0].orgType);

            this.loginService.saveDetails(data, this.loginForm.value);
            this.toastrService.success('Success!! You have successful login');
            this.loginService.setLoginResponse(true);

            if (data.Details[0].agreementStatus === 0) {

              this.route.navigate(['/useragreement']);

            } else if (data.Details[0].fLoginStatus === 'Yes') {

              this.route.navigate(['/changepassword']);

            } else {

              // localStorage.setItem("expirePlanDays", data.controlMsg);
              this.localStorage.createLocalStorage("expirePlanDays", "" + data.controlMsg);
              this.localStorage.createLocalStorage("expirePopupCount", "0");

              if (data.passMsg == 0 || data.passMsg < 0) {
                this.route.navigate(['/passwordSecurity']);
              }
              else if (parseInt(data.controlMsg) <= 30) {
                if (parseInt(data.controlMsg) < 0) {
                  // this.route.navigate(['/expirePlan']);
                  // this.toastrService.error("Your Plan Is Expired ");
                  this.route.navigate(['/mainlogin']);
                } else {
                  // localStorage.setItem("expirePlanDays", data.controlMsg);
                  this.localStorage.createLocalStorage("expirePlanDays", "" + data.controlMsg);
                  this.localStorage.createLocalStorage("expirePopupCount", "0");
                  // this.toastrService.error("Your Plan Is Going To Expired In " + data.controlMsg + " Days");
                  this.route.navigate(['/mainlogin']);
                }
              }
              else if (data.Details[0].emailStatus === 0) {

                this.route.navigate(['/emailverification']);

              }
              else {
                this.route.navigate(['/mainlogin']);
              }
            }


          } else if (data.Status === 'Success' && data.Details[0].status === 0) {

            this.toastrService.error('Your User ID has been Disabled, Please Contact your Admin to activate it again');
          }

          else if (data.Status === 'Success' && data.Details[0].emailStatus === 2) {

            this.uerEmailId = data.Details[0].userEmail;
            this.warningMessage = true;

            const obj = {
              'emailId': data.Details[0].userEmail,
              'userId': data.Details[0].userID
            };
            this.api.postMethod('userActivationEmail', obj).subscribe(data => {

              if (data.Status === 'Success') {

                console.log("Activation mail sent successfully")
                // this.router.navigate(['/mainlogin']);

                // this.localStorageObj.createLocalStorage('resetpassword', 'false');
              } else {
                this.toastrService.error(data.reason);
              }
            });

          } else {
            this.checkCaptcha();
            this.toastrService.error('', data.Reason);

          }
        } else {
          this.checkCaptcha();
          this.toastrService.error('', data.Reason);
        }

      });
  }

  username: any;
  password: any;
  captcha: any;

  spEmailId: any;
  spcontactPersonName: any;
  spcontactPersonNumber: any;

  onSubmit() {
    this.uerEmailId = null;
    // return false;
    if (this.loginForm.valid) {
      this.username = this.loginForm.value.username;
      this.password = this.loginForm.value.password;
      this.captcha = this.loginForm.value.captcha;

      this.loginDetails = {
        'username': CryptoJS.AES.encrypt(this.username, this.secreteKey) + "",
        'password': CryptoJS.AES.encrypt(this.password, this.secreteKey) + "",
        'captcha': CryptoJS.AES.encrypt(this.captcha, this.secreteKey) + "",
        'captchId': CryptoJS.AES.encrypt(this.captchaId, this.secreteKey) + "",
        'loginFrom': CryptoJS.AES.encrypt("eFilling", this.secreteKey) + "",
        'isContinue': CryptoJS.AES.encrypt("No", this.secreteKey) + ""
      };


      // ************** New login method ********************
      this.loginService.newLogin(this.loginDetails)
        .subscribe(data => {

          console.log(' data passmsg => ', data.passMsg);

          try {
            this.spEmailId = data.Details[0].emailID;
            this.spcontactPersonName = data.Details[0].contactPersonName;
            this.spcontactPersonNumber = data.Details[0].contactPersonNumber;
            this.localStorage.createLocalStorage("spEmailId", this.spEmailId);
            this.localStorage.createLocalStorage("spcontactPersonName", this.spcontactPersonName);
            this.localStorage.createLocalStorage("spcontactPersonNumber", this.spcontactPersonNumber);



          } catch (error) {
            this.localStorage.createLocalStorage("spEmailId", '');
            this.localStorage.createLocalStorage("spcontactPersonName", '');
            this.localStorage.createLocalStorage("spcontactPersonNumber", '');


          }

          if (data.Status === 'Warning') {
            this.displaypopup = true;
          }
          else if (data.Status === 'Success') {

            try {
              this.localStorage.createLocalStorage("ChangedPasswordSecurity", " " + data.passMsg);
            } catch (error) {
              this.localStorage.createLocalStorage("ChangedPasswordSecurity", '');
            }



            this.localStorage.createLocalStorage('sessionKey', data.Details[0].sessionKey); //used to main user session
            this.localStorage.createLocalStorage('empID', JSON.stringify(data.Details[0].userID));
            this.localStorage.createLocalStorage("ID", JSON.stringify(data.Details[0].companyID));//For Dev

            if (data.Status === 'Success' && data.Details[0].status === 1
              && data.Details[0].emailStatus === 1 || data.Details[0].emailStatus === 0) {

              //this.localStorage.createLocalStorage("sessionKey", JSON.stringify(data.Details[0].sessionKey)); //For Dev
              this.localStorage.createLocalStorage('token', data.token);
              this.localStorage.createLocalStorage('orgType', data.Details[0].orgType);

              this.loginService.saveDetails(data, this.loginForm.value);
              this.toastrService.success('Success!! You have successful login');
              this.loginService.setLoginResponse(true);

              if (data.Details[0].agreementStatus === 0) {

                this.route.navigate(['/useragreement']);

              } else if (data.Details[0].fLoginStatus === 'Yes') {

                this.route.navigate(['/changepassword']);

              } else {

                // localStorage.setItem("expirePlanDays", data.controlMsg);
                this.localStorage.createLocalStorage("expirePlanDays", "" + data.controlMsg);
                this.localStorage.createLocalStorage("expirePopupCount", "0");

                if (data.passMsg == 0 || data.passMsg < 0) {
                  this.route.navigate(['/passwordSecurity']);
                }
                else if (parseInt(data.controlMsg) <= 30) {

                  if (parseInt(data.controlMsg) < 0) {
                    // this.route.navigate(['/expirePlan']);
                    // this.toastrService.error("Your Plan Is Expired ");
                    this.route.navigate(['/mainlogin']);
                  } else {
                    // this.toastrService.error("Your Plan Is Going To Expired In " + data.controlMsg + " Days");
                    // localStorage.setItem("expirePlanDays", data.controlMsg);
                    this.localStorage.createLocalStorage("expirePlanDays", "" + data.controlMsg);
                    this.localStorage.createLocalStorage("expirePopupCount", "0");
                    this.route.navigate(['/mainlogin']);
                  }
                }
                else if (data.Details[0].emailStatus === 0) {

                  this.route.navigate(['/emailverification']);

                }
                else {
                  this.route.navigate(['/mainlogin']);
                }
              }


            } else if (data.Status === 'Success' && data.Details[0].status === 0) {

              this.toastrService.error(' Your account is deactivated by your admin, Please contact your admin for Re-activating it again.');
            }

            else if (data.Status === 'Success' && data.Details[0].emailStatus === 2) {

              this.uerEmailId = data.Details[0].userEmail;
              this.warningMessage = true;

              const obj = {
                'emailId': data.Details[0].userEmail,
                'userId': data.Details[0].userID
              };
              this.api.postMethod('userActivationEmail', obj).subscribe(data => {

                if (data.Status === 'Success') {

                  console.log("Activation mail sent successfully")
                  // this.router.navigate(['/mainlogin']);

                  // this.localStorageObj.createLocalStorage('resetpassword', 'false');
                } else {
                  this.toastrService.error(data.reason);
                }
              });

            } else {
              this.checkCaptcha();
              this.toastrService.error('', data.Reason);

            }
          } else {
            this.checkCaptcha();
            this.toastrService.error('', data.Reason);
          }
        });
    }

  }
  passwordIcon: boolean;
  togglePasswordVisibility() {

    if (this.passwordIcon === true) {
      this.passwordIcon = false;
    }
    else {
      this.passwordIcon = true;
    }
  }

  formRegistration(formdata) {
    var obj = {
      'gstin': formdata.gstin,
      'mobile': formdata.mobile,
      'email': formdata.email
    }

    this.loginService.registration(obj).subscribe(data => {
      if (data.Status == 'Fail') {
        this.toastrService.error('This User Already Registered.');
      }
      if (data.Status == 'Success') {
        this.toastrService.success('Success!! New User Successfully Registered.');
        this.SubmitBtn = false
        this.RegistrationBtn = true
        this.registrationForm.controls['gstin'].enable();
        this.registrationForm.controls['mobile'].enable();
        this.registrationForm.controls['email'].enable();
        this.registrationForm.reset();
        this.OTPValidation.reset();
        this.clickLoginOnRegistrationPage();

      }
    })


  }
  SubmitBtn: Boolean = false
  RegistrationBtn: Boolean = true
  checkotp: Boolean = true
  StoreOTP: any;

  OTP() {
    this.loginService.getOTP(this.myEmail).subscribe(data => {
      if (data.Status == "Success") {
        this.toastrService.success(data.Reason)
        this.StoreOTP = data.OTP
        this.SubmitBtn = true
        this.RegistrationBtn = false
        this.registrationForm.controls['gstin'].disable();
        this.registrationForm.controls['mobile'].disable();
        this.registrationForm.controls['email'].disable();
      } else {
        this.toastrService.error(data.Reason)
      }

    })

  }

  CheckOTP(e) {
    var verOTP = e.target.value;
    if (verOTP.length == 6) {
      if (verOTP == this.StoreOTP) {
        this.checkotp = false
      }
      else {
        this.checkotp = true
      }
    } else {
      this.checkotp = true
    }

  }


  hideVerificationPopUp() {
    this.warningMessage = false;
  }
  ngOnDestroy() {
    this.warningMessage = false;
    document.getElementsByTagName("body")[0].removeAttribute("id");
  }
}
