import { LocalStorageService } from './../service/local-storage.service';
import { CompanyService } from './../service/companylist/company.service';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { LoginService } from '../service/auth/login.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/http/api.service';
import { Router } from '@angular/router';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { ShareProductService } from '../user-admin/service/share-product.service';
import { MenufooterService } from '../service/menufooter.service';
import { TgAdminService } from '../user-admin/formpages/Service/tg-admin.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { ExcelExportService } from '../service/excel-export/excel-export.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy
  {
  @ViewChild('passwrodmodal')
  passwrodmodal:ModalDirective;
  @ViewChild('setMailModal')
  setMailModal: ModalDirective;
  @ViewChild('showWallet')
  showWallet: ModalDirective;

  @ViewChild('createUser')
  createUser: ModalDirective;

  // @ViewChild('trainingVideoModel')
  // trainingVideoModel: ModalDirective;

  // @ViewChild('videoPlayer') videoPlayer: ElementRef;

  changePassForm : FormGroup;
  chgpassvalid :boolean=false;
  menuparentID :any;
  onShow:boolean=true;
  responseMessage: any;
  companyName: any;
  lastLogin: any;
  subscription: Subscription;
  subForFrame = new Subscription();
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  gstin_number: any;
  company_name: any;
  getactionActive:any;
  state: String;
  object: any;
  subMenuActive:any;
  hiddeMenu: boolean = false;
  productData = []; menuItemData = []; subMenuList = [];
  menuItem: boolean = true; subMenuStatu: boolean = false; isDisabled: boolean = false;
  errornmessage:boolean = true;
  errorcmessage:boolean = true;
  menuId: any = 0
  Subsidiary:boolean=false;
  State:boolean=false;
  Statedropdown:FormGroup;
  navbarhides :boolean= false;
  UserOrgType:any;
  setSpMailForm:FormGroup;
  particularSphistorybalanceList: any = [];
  ExcelArray: any = [];
  uploadPage:boolean = true;
  gstr1Page:boolean = true;
  underConstructionPage:boolean = true;
  cols4 = [
    { field: 'srno', header: 'Sr No.', width: '50px' },
    { field: 'Sender', header: 'Sender',width: '150px' },
    { field: 'Receiver', header: 'Receiver',width: '150px' },
    { field: 'Opening_Balance', header: 'Opening Balance',width: '150px' },
    { field: 'Amount_transaction', header: 'Amount Transaction',width: '150px' },
    { field: 'Closing_Balance', header: 'Closing Balance',width: '150px' },
    { field: 'Amount_Deducted_On', header: 'Date',width: '150px' },
  ];
  assignModal = false;
  video: boolean = true;
  constructor(private loginService: LoginService, private tgAdminServiceObj: TgAdminService,
    private localStorage: LocalStorageService, private api: ApiService, public route: Router,
    private toastr: ToastrService, private mfService: MenufooterService, private excelExport: ExcelExportService,

  ) {
    this.getactionlist();
    this.Statedropdown = new FormGroup({
      Statelist: new FormControl(null),
    });
    this.changePassForm = new FormGroup({
      currpass: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      newpass: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      confnewpass: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
    });

    this.loginService.setLoginResponse(false);

    this.company_name = localStorage.getLocalStorage('company_name_for_header');
    var  UserMappedRecord = JSON.parse(this.localStorage.getLocalStorage('UserMappedRecord'));

    // this.sub1 = this.gstr1.getsubstate().subscribe(date => {
    //   UserMappedRecord = JSON.parse(this.localStorage.getLocalStorage('UserMappedRecord'));
    //   if(UserMappedRecord != null ){
    //     this.company_name = UserMappedRecord.HeaderCompanyName;
    //     if(UserMappedRecord.GSTNINNO != null){
    //       this.gstin_number = UserMappedRecord.GSTNINNO;
    //       var stateCode = this.gstin_number.substring(0,2);
    //       this.state = this.stateCodeObj.getStateCode(stateCode);
    //     }

    //   }
    // })
    //   if (UserMappedRecord != null ) {
    //     this.company_name = UserMappedRecord.HeaderCompanyName;
    //     if (UserMappedRecord.GSTNINNO != null) {
    //       this.gstin_number = UserMappedRecord.GSTNINNO;
    //       var stateCode = this.gstin_number.substring(0, 2);
    //       this.state = this.stateCodeObj.getStateCode(stateCode);
    //     }

    //   }

      this.setSpMailForm = new FormGroup({
        email: new FormControl('', [Validators.email,Validators.required]),
        password: new FormControl('', Validators.required),
      })

  }

  ngOnInit() {

    // this.api.getsubmenudata.subscribe(res=>{
    //   this.getSubMenu(res);
    //   this.subMenuActive = res.ParentId;
    // })

    this.getAllTabs();
  }
  subsidiaryListArray:any;
  stateListArray:any
  getListOfCompanyByTypeSubsidiary(hqID) {
    this.api.getMethod('getListOfCompanyByTypeAndParentID?&heirarchyType=' + 2 + '&parentID=' + hqID)
      .subscribe(response => {
        this.subsidiaryListArray = response.companyList;
      });
  }
  StateArray: any = [];
  statestring: any;
  getListOfCompanyByTypeState(subsidiaryID) {

    this.api.getMethod('getListOfCompanyByTypeAndParentID?&heirarchyType=' + 3 + '&parentID=' + subsidiaryID)
    .subscribe(response => {
      this.stateListArray = response.companyList;
      for (let i = 0; i < this.stateListArray.length; i++) {
        var one = this.stateListArray[i].companyName.concat(' .  [ ');
        var two = one.concat(this.stateListArray[i].GSTNINNO);
        this.statestring = two.concat('  ]');
         this.StateArray[i] = {
          'statestring': this.statestring,
          'GSTNINNO': this.stateListArray[i].GSTNINNO,
          'PAN': this.stateListArray[i].PAN,
          'ServiceState': this.stateListArray[i].ServiceState,
          'StateCode': this.stateListArray[i].StateCode,
          'companyID': this.stateListArray[i].companyID,
          'companyName': this.stateListArray[i].companyName
         };
        }
        this.stateListArray = this.StateArray;

    });
  }


  showpasswordmodal(){
    this.passwrodmodal.show();
  }

  closepasswordmodal(){
    this.passwrodmodal.hide();
  }
  getAllTabs() {

          sessionStorage.setItem('menu', 'false');
          sessionStorage.setItem('subMenu', 'false');
          sessionStorage.setItem('acion', 'false');
          this.subForFrame = this.api.getDefaultFrame().subscribe(data => {
          if (data) {
          sessionStorage.setItem('menu', 'false');
          sessionStorage.setItem('subMenu', 'false');
          sessionStorage.setItem('acion', 'false');
          this.api.setMainFrameFooter(false);
          this.getMenu();
        }
      });
       this.getActiveAction();
     }

  getMenu() {
    
    this.subMenuList = null;
    this.menuItemData = null;
    var productData: any = this.localStorage.getLocalStorage('productkey');
    var prodObj = JSON.parse(productData);
        if (prodObj.Action === 1) {
          sessionStorage.setItem('menu', 'false');
          sessionStorage.setItem('subMenu', 'false');
          sessionStorage.setItem('acion', 'false');
          this.localStorage.createLocalStorage('htmlPageId', prodObj.ProductId ? JSON.stringify(prodObj.ProductId) : '0');
          this.menuItem = true;

        this.route.navigate([prodObj.Landing_Page], {skipLocationChange: true}); //++
        } else if (prodObj.Action === 0) {
            this.api.getMethod('getmenulist?roleId=' + this.localStorage.getLocalStorage('roleId') + '&productId=' + prodObj.ProductId).subscribe(data => {
            this.menuItemData = data.menuList;
            this.menuItem = false;
            this.getSubMenu(this.menuItemData[0]);
          })
        }
  }

  getSubMenu(menu) {

    console.log("------------>",menu)
    this.menuparentID = null;
    this.getactionActive = null;
    this.api.getsubmenudata.subscribe(res=>{
      this.getSubMenu(res);
      this.getactionActive = res.ProductId;
    })
    this.subMenuActive = null;
    sessionStorage.setItem("subMenu", 'true');
    sessionStorage.setItem("subMenuData", JSON.stringify(menu));

  if (menu.Action === 1) {
      sessionStorage.setItem("acion", 'true');
      sessionStorage.setItem("actionData", JSON.stringify(menu));
      this.api.setMainFrameFooter(true);
      this.subMenuStatu = true; // This varible to hidde submenu list after click on menu directly open action
    }
     else if(menu.Action === 2){
      this.api.setMainFrameFooter(false);
       try {
        this.localStorage.createLocalStorage('htmlPageId',menu.ProductId)
       } catch (error) {
        this.localStorage.createLocalStorage('htmlPageId',0)
       }
     
      this.route.navigate([menu.Landing_Page], {skipLocationChange:true})
        sessionStorage.setItem("reportData",menu.ProductId);
      this.subMenuStatu = false
    }
    else {
      this.menuId = menu.ProductId;
      this.api.getMethod('getsubmenulist?roleId=' + this.localStorage.getLocalStorage('roleId') + '&productId=' + menu.ProductId)
        .subscribe(data => {
          console.log("data.SubMenuList---",data.SubMenuList,"---------this.subMenuStatu----",this.subMenuStatu)
          if (data.SubMenuList.length > 0) {

            this.subMenuList = data.SubMenuList;
            this.subMenuStatu = false;
            console.log("this.subMenuList---",this.subMenuList,"---------this.subMenuStatu----",this.subMenuStatu)

            this.api.setMainFrameFooter(false); // This method is used to hidde action menu
            if (menu.Action === 0) {
              sessionStorage.setItem("acion", 'true');
              sessionStorage.setItem("actionData", JSON.stringify(data.SubMenuList[0]));
              this.api.setMainFrameFooter(true);
            }

          }
        })
    }
  }

    // for Side SubMenu/Action
  openSubmenu(menu) {
    if (menu.Action === 1){
      $('#ActionId').css('width', "250px");
    }
   }
  // /for Side SubMenu/Action

  getActionMenu(subMenu) {
    console.log('sub menu ===> '+JSON.stringify(subMenu))
    this.menuparentID=null;
    this.subMenuActive = subMenu.ParentId;
    localStorage.setItem("orgType", subMenu.Prod_name);
    sessionStorage.setItem("acion", 'true');
    sessionStorage.setItem("actionData", JSON.stringify(subMenu));
    this.api.setMainFrameFooter(true);
    this.getactionActive=null;

  }

  getactionlist(){
    this.sub2 = this.mfService.getfooter().subscribe(data => {
    })

  }

  logout() {
    try {
      var object = {
        'userID': this.localStorage.getLocalStorage('empID') || ''
      }
      this.api.postMethod('newLogOutWeb' , object)
        .subscribe(response => {
          console.log('response Log Out ' + response)
        });
    }
    catch{ }
    sessionStorage.removeItem("ID");
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.route.navigate(['/login']);
    this.api.setsessionKey='';
    this.api.setEmpID='';
  }

  getActiveAction(){

    this.mfService.getFromFooter().subscribe(data=>{
      this.getactionActive=data;
      try{
      for(var i=0;i<this.subMenuList.length;i++){
        if(this.subMenuList[i].ProductId==this.getactionActive){
          this.menuparentID=this.subMenuList[i].ParentId;
        }
      }
    }catch{}

    });
  }

  // ** COPIED GSTIN  NO ** //

  copyToClipboard(){
    var el = document.getElementById('inputId');
    el.setAttribute('contenteditable','true');
    el.focus();
    document.execCommand('selectAll');
    document.execCommand('copy');
    el.setAttribute('contenteditable','false');
    el.blur();
    this.toastr.success('GSTIN Number Copied!',this.gstin_number);
    // ToastrModule.forRoot({positionClass: 'right'});
  }
  // ** /COPIED GSTIN ** NO //



  goProductPage(){

    var  UserMappedRecord = JSON.parse(this.localStorage.getLocalStorage("temporaryUserMappedRecord"));
    var UserMappedRecorddata : any = {
      "companyID": UserMappedRecord.companyID,
      "GSTNINNO" : UserMappedRecord.GSTNINNO,
      "companyname" : UserMappedRecord.companyname,
      "RoleName" : UserMappedRecord.RoleName,
      "org_type" : UserMappedRecord.org_type,
      "org_Hierarchy": UserMappedRecord.org_Hierarchy,
      "roleId": UserMappedRecord.roleId || '',
      "HeaderCompanyName":UserMappedRecord.HeaderCompanyName,
      "HQ_Id":UserMappedRecord.HQ_Id || null,
      "SUB_Id":UserMappedRecord.SUB_Id || null,
      "STATE_Id":UserMappedRecord.STATE_Id || null,
      "BRANCH_ID": UserMappedRecord.BRANCH_ID||null,
      };
    this.localStorage.createLocalStorage("UserMappedRecord", JSON.stringify(UserMappedRecorddata))
    this.localStorage.createLocalStorage("stateNameforDropDown","null");
    this.localStorage.createLocalStorage("subNameforDropDown","null");
    localStorage.setItem("subIDforDropDown",null);


    this.tgAdminServiceObj.setHomeButtonStatus(UserMappedRecord.companyname);
    this.route.navigate(['/productpage']);
  }

  logoutShow(){
    this.onShow =false;
  }

  onlogoutClose(){
    this.onShow =true;
  }

  onSubmitChangePassword(){

    const empID : any = this.localStorage.getLocalStorage("empID");
    const obj = {
      'oldPassword':this.changePassForm.value.currpass,
      'newPassword': this.changePassForm.value.newpass,
      'userID': empID
    };


    this.api.postMethod('changePassword',obj).subscribe(data=>{
        if(data.Status == 'Success'){
           this.toastr.success("Password Changed Successfully")
           this.passwrodmodal.hide();
         }else{
          this.toastr.error(data.Reason)
          this.passwrodmodal.hide();
         }
       })
  }
  checkNewPass(e){
    if(this.changePassForm.value.currpass === this.changePassForm.value.newpass){
      this.chgpassvalid= false;
    }
    else if(this.changePassForm.value.newpass === this.changePassForm.value.confnewpass){
      this.chgpassvalid= true;
    }
    else{
      this.chgpassvalid= false;
    }
    var newpas = e.target.value;
    if(this.changePassForm.value.currpass === newpas){
      this.errornmessage = false;
    }
    else{

      this.errornmessage = true;

  }
}
  checkCNewPass(e){

    if(this.changePassForm.value.currpass === this.changePassForm.value.newpass){
      this.chgpassvalid= false;
    }
    else if(this.changePassForm.value.newpass === this.changePassForm.value.confnewpass){
      this.chgpassvalid= true;
    }
    else{
      this.chgpassvalid= false;
    }
    var checkcnewpas = e.target.value;
    if(this.changePassForm.value.newpass === checkcnewpas){
      this.errorcmessage = true;
    }
    else{
      this.errorcmessage = false;
    }
  }
  passwordIcon1: boolean;
  passwordIcon2: boolean;
  passwordIcon3: boolean;

  togglePasswordVisibility1(){
    if(this.passwordIcon1 === true){
        this.passwordIcon1 = false;
     }
      else{
          this.passwordIcon1 = true;
      }
    }

    togglePasswordVisibility2(){
      if(this.passwordIcon2 === true){
        this.passwordIcon2 = false;
          }
        else{
            this.passwordIcon2 = true;
        }
    }

    togglePasswordVisibility3(){
      if(this.passwordIcon3 === true){
        this.passwordIcon3 = false;
        }
        else{
          this.passwordIcon3 = true;
        }
    }
  ngOnDestroy(){
    this.subForFrame.unsubscribe();
    // this.sub1.unsubscribe();
    // this.sub2.unsubscribe();
  }

  navbarhideshow(){
    if(this.navbarhides===true){
      this.navbarhides = false;
    }
    else{
      this.navbarhides = true;
    }
  }

  getDetailsOfLogin(){
    this.UserOrgType=null;
     var UserMappedRecord = JSON.parse(this.localStorage.getLocalStorage("UserMappedRecord"));
     this.UserOrgType=UserMappedRecord.org_type;
  }

  hideSetSpMailDetails() {
    this.setMailModal.hide();
  }
  showSetSpMailDetails() {
    this.setMailModal.show();
    var loginDetails = JSON.parse(this.localStorage.getLocalStorage("detail"));
    var spEmailDetails ={
     'created_by':loginDetails.companyID
    }

    this.api.postMethod('getemailcredentials',spEmailDetails).subscribe(res => {
      if(res.getemailcredentials.length !== 0){
        this.setSpMailForm.patchValue({
          'email':res.getemailcredentials[0].from_email_id,
          'password':res.getemailcredentials[0].from_password
        })
      }
      else{
        this.setSpMailForm.patchValue({
          'email':'',
          'password':''
        })
      }

    });
  }
  setSpMailMethod(data) {
    var loginDetails = JSON.parse(this.localStorage.getLocalStorage("detail"));

    var spEmailDetails = {
      'from_email_id': data.email,
      'from_password': data.password,
      'created_by': loginDetails.companyID,
      'template_name':loginDetails.companyname
    }
    this.api.postMethod('setemailcredentials', spEmailDetails).subscribe(res => {
      if(res.status === 'Success'){
        this.toastr.success(res.reason);
        this.setMailModal.hide();
      }
      else{
        this.toastr.error(res.reason);
      }
    });

  }
  passwordIcon: boolean;
  togglePasswordVisibility() {

    if (this.passwordIcon === true) {
      this.passwordIcon = false;
    }
    else {
      this.passwordIcon = true;
    }
  }
  getWidthchild(){

    if (this.subMenuList.length <= 1 || this.subMenuList.length === 1) {
      let width = 30/this.subMenuList.length;
      return width+'%';
    } else {
      let width = 100/this.subMenuList.length;
      return width+'%';
    }
  }


  getWidthsubmenu(){

    if(this.menuItemData.length <= 1 || this.menuItemData.length === 1){
      let width = 30/this.menuItemData.length;
      return width+'%';
    } else {
      let width = 100/this.menuItemData.length;
      return width+'%';
    }
  }

  rembalance:any;
  usedAmt:any;
  walletShow(){
    this.usedAmt=0;
    this.rembalance=null;
    var loginDetails = JSON.parse(this.localStorage.getLocalStorage("detail"));
    let totalAmt=0;
    this.particularSphistorybalanceList = [];
    this.api.getMethod('getbalancehistorylist?&companyID=' + loginDetails.companyID).subscribe(
      res => {
        this.particularSphistorybalanceList = res.balancelisthistory;
        for(let i=0; i<this.particularSphistorybalanceList.length; i++){
          if(loginDetails.companyID == this.particularSphistorybalanceList[i].SenderID){
            totalAmt= totalAmt+parseInt(this.particularSphistorybalanceList[i].Amount_transaction);
          }
        }
        this.usedAmt=totalAmt;
        this.rembalance=res.remainingBalance;
      }
    )
    this.showWallet.show();
  }

    // excel for SP
    exportExcelForSp(jsonArray) {
      this.ExcelArray = [];
      for (let i = 0; i < jsonArray.length; i++) {
        var object = {
          'Sender': jsonArray[i].Sender,
          'Receiver': jsonArray[i].Receiver,
          'Opening_Balance': jsonArray[i].Opening_Balance,
          'Closing_Balance': jsonArray[i].Closing_Balance,
          'Amount_transaction': jsonArray[i].Amount_transaction,
          'Amount_Deducted_On': jsonArray[i].Amount_Deducted_On
        };

        this.ExcelArray.push(object);
      }
      let cols = ['Sender', 'Receiver', 'Opening Balance', 'Amount Transaction', 'Closing Balance', 'Date'];
      let key = [
        { key: 'Sender' }, { key: 'Receiver' }, { key: 'Opening_Balance' }, { key: 'Amount_transaction' }, { key: 'Closing_Balance' }, { key: 'Amount_Deducted_On' },
      ];
      this.excelExport.exportExcelAll("SP Wallet History", "SP_Wallet_History.xlsx", cols, key, 1, this.ExcelArray);

    }


  showHelpPopup(status){

    let pageId = this.localStorage.getLocalStorage('htmlPageId');
    console.log('page Id => '+this.localStorage.getLocalStorage('htmlPageId'));
    console.log('status => '+status)

    if((pageId === '10' || pageId === '11' || pageId === '9' || pageId === '64') && status === '1'){
      this.gstr1Page = false;
      this.uploadPage = true;
      this.underConstructionPage = true;
    } else if((pageId === '98' || pageId ==='99') && status === '1') {
      this.gstr1Page = true;
      this.uploadPage = false;
      this.underConstructionPage = true;
    } else {
      this.gstr1Page = true;
      this.uploadPage = true;
      this.underConstructionPage = false;
    }
//this.assignModal = true;
this.createUser.show();

  }

  // showTraingVideo(status) {
  //   this.trainingVideoModel.show();
  //   this.video = true;
  // }

  // hideCreateUserDetails(){
  //   // this.assignModal = false;
  //   this.createUser.hide();
  //   this.trainingVideoModel.hide();
  // }

//   toggleVideo(event: any) {
    
//    this.videoPlayer.nativeElement.pause();
//    // this.videoPlayer.autoplay = true;
//      this.trainingVideoModel.hide();
//     this.video = false;
// }
}
