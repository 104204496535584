import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../service/auth/login.service';
import { LocalStorageService } from '../service/local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from '../service/http/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-emailverification',
  templateUrl: './emailverification.component.html',
  styleUrls: ['./emailverification.component.css']
})
export class EmailverificationComponent implements OnInit {
  optHidden = false;
  verifyEmailForm: FormGroup;
  veryfyOtp: FormGroup;
  emailID: any;
  emailAddress:any='Support@taxgenie.com';
  mailVerification = true;
  constructor(private loginService: LoginService, private localStorageObj: LocalStorageService,
    private router: Router, private api: ApiService, private toster: ToastrService) {

    this.localStorageObj.createLocalStorage('emailVerificationStatus', 'true');
    this.verifyEmailForm = new FormGroup({
      emailid : new FormControl({value: '', disabled: false}, Validators.compose([Validators.required, Validators.email]))
      
    });
    this.veryfyOtp = new FormGroup({
      otp : new FormControl('',[Validators.required,Validators.pattern('^[0-9]{4}$')])
    });
  }

  ngOnInit() {
  }
  logout() {
    sessionStorage.removeItem('ID');
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.router.navigate(['/login']);
  }

  verifyEmail(data) {
    this.emailID = data.emailid;
    const loginDetails = JSON.parse(this.localStorageObj.getLocalStorage('detail'));
    const object = {
      'userID': loginDetails.userID,
      'emailid': data.emailid
    };
    this.api.postMethod('sendOtp', object).subscribe(re => {
      if (re.Status === 'Success') {
        this.mailVerification = false;
        this.verifyEmailForm.reset();
        this.toster.success(re.Reason); 
      } else {
        this.toster.error(re.Reason);
      }
    });

  }

  submitOtp(data) {
    const loginDetails = JSON.parse(this.localStorageObj.getLocalStorage('detail'));

    const object = {
      'otp': data.otp,
      'userID': loginDetails.userID,
      'emailID': this.emailID
    };
    this.api.postMethod('verifyOtp', object).subscribe(response => {
      if (response.Status === 'Success') {
        this.toster.success(response.Reason);
        this.localStorageObj.createLocalStorage('emailVerificationStatus', 'false');
        this.veryfyOtp.reset();
        //this.toster.success('Success!! You have successful login');
        this.router.navigate(['/mainlogin']);
     } else {
        this.toster.error(response.Reason);
        //this.localStorageObj.createLocalStorage('emailVerificationStatus', 'false');
        //this.logout();
      }
    });
  }

  goBack() {
    this.veryfyOtp.reset();
    this.mailVerification = true;
  }

}
