import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { MainComponent } from './main/main.component';
import { MainLoginComponent } from './user-admin/formpages/main-login/main-login.component';
import { ProductPageComponent } from './user-admin/formpages/product-page/product-page.component';
import { UndermaintenanceComponent } from './undermaintenance/undermaintenance.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ExpirePlanComponent } from './expire-plan/expire-plan.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { EmailverificationComponent } from './emailverification/emailverification.component';
import { UserActivationPageComponent } from './user-activation-page/user-activation-page.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { ComingSoonTabComponent } from './coming-soon-tab/coming-soon-tab.component';
import { ChangedPasswordSecurityComponent } from './changed-password-security/changed-password-security.component';





//Company assigning pages

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'products', loadChildren: 'src/app/allmodule/allmodule.module#AllmoduleModule', canActivate: [AuthGuard]},
  {path: 'main', component: MainComponent, canActivate: [AuthGuard]},
  {path: 'mainlogin', component: MainLoginComponent, canActivate: [AuthGuard]},
  {path: 'productpage', component: ProductPageComponent, canActivate: [AuthGuard]},
  {path: 'underdevelopment', component: UndermaintenanceComponent, canActivate: [AuthGuard]},
  {path: 'changepassword', component: ChangepassComponent, canActivate: [AuthGuard]},
  {path: 'comingsoon', component: ComingSoonComponent, canActivate: [AuthGuard]},
  {path: 'expirePlan', component: ExpirePlanComponent, canActivate: [AuthGuard]},
  {path: 'resetpasswordpage', component: ResetpasswordComponent},
  {path: 'forgotpassword', component: ForgotpasswordComponent},
  {path: 'emailverification', component: EmailverificationComponent, canActivate: [AuthGuard]},
  {path: 'useractivation', component: UserActivationPageComponent},
  {path: 'useragreement', component: UserAgreementComponent,canActivate: [AuthGuard]},
  {path: 'comingSoonTab', component: ComingSoonTabComponent,canActivate: [AuthGuard]},
  {path: 'passwordSecurity', component: ChangedPasswordSecurityComponent, canActivate: [AuthGuard]}
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{ useHash: true }),
    CommonModule
  ],
  exports : [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
