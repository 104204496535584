import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/auth/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalStorageService } from '../service/local-storage.service';
import { ApiService } from '../service/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.css']
})
export class ChangepassComponent implements OnInit {
  resetPassForm: FormGroup;
  chgpassvalid = false;
  errorcmessage = true;
  minandmaxmessage = true;

  constructor(private loginService: LoginService, private router: Router, private localStorageObj: LocalStorageService,
               private api: ApiService, private toaster: ToastrService) {
    this.localStorageObj.createLocalStorage('resetpassword', 'true');
    this.resetPassForm = new FormGroup({

      newpass : new FormControl('', [Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      confnewpass : new FormControl('', [Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
    });

   }

   ngOnInit() {
  }

   onSubmitChangePassword() {

    const empID: any = this.localStorageObj.getLocalStorage('empID');

    const obj = {
      'newPassword': this.resetPassForm.value.newpass,
      'userID': empID
    };

    // this.api.postMethod('resetPasswordEmailVerification', obj).subscribe(data => {

    //       if (data.Status === 'Success') {
    //          this.toaster.success('Password Updated Successfully');

    //         // this.router.navigate(['/mainlogin']);

    //           this.localStorageObj.createLocalStorage('resetpassword', 'false');
    //        } else {
    //         this.toaster.error(data.reason);
    //        }
    //      });

    this.api.postMethod('resetPassword',obj).subscribe(data => {

        if (data.Status === 'Success') {
           this.toaster.success('Password Reset Successfully');

           this.router.navigate(['/mainlogin']);

            this.localStorageObj.createLocalStorage('resetpassword', 'false');
         } else {
          this.toaster.error(data.reason);
         }
       });
  }


  checkminandmax(e){
    var check = e.target.value;
      if (check.length >= 8){
        this.minandmaxmessage = true;
      } else {
        this.minandmaxmessage = false;
      } 
    console.log("CHECK => ", check);
  } 

  checkCNewPass(e) {
    if (this.resetPassForm.value.currpass === this.resetPassForm.value.newpass) {
      this.chgpassvalid = false;
    } else if (this.resetPassForm.value.newpass === this.resetPassForm.value.confnewpass){
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var checkcnewpas = e.target.value;

    if (this.resetPassForm.value.newpass === checkcnewpas) {

      this.errorcmessage = true;
    } else {
      this.errorcmessage = false;
    }
  }

  logout() {
    sessionStorage.removeItem('ID');
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.router.navigate(['/login']);
  }

  newpasswordIcon: boolean;
  togglenewPasswordVisibility() {

    if (this.newpasswordIcon === true) {
      this.newpasswordIcon = false;
    }
    else {
      this.newpasswordIcon = true;
    }
  }

  confirmpasswordIcon: boolean;
  toggleconfirmPasswordVisibility(){
    if (this.confirmpasswordIcon === true) {
      this.confirmpasswordIcon = false;
    }
    else {
      this.confirmpasswordIcon = true;
    }
  }

}
