import { Injectable } from '@angular/core';
import { Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenufooterService {
  menuFooterMediator = new Subject<any>(); 
  subject1 = new Subject<any>();
  constructor() { }
  setFromFooter(data: any){
    this.menuFooterMediator.next(data);
  }

  getFromFooter() :Observable<any>{
    return this.menuFooterMediator.asObservable();
  }

  setfooter(data: any){
    this.subject1.next(data);
  }
  getfooter() :Observable<any>{
    return this.subject1.asObservable();
   
  }

}
