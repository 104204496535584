import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { LocalStorageService } from './service/local-storage.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  previousUrl = '';
  constructor(private localStorage: LocalStorageService) {
  }
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (environment.production) {
      if (chunkFailedMessage.test(error.message)) {
        // window.history.back();
        window.location.reload();
        let x = this.localStorage.getLocalStorage('currUrl');
        console.log('Global :: ', x);
      }
    } else {
      throw error;
    }
   }
}

