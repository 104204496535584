import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalStorageService } from '../service/local-storage.service';
import { ApiService } from '../service/http/api.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  resetPassForm: FormGroup;
  passwordIcon: boolean;
  passwordIcon1: boolean;
  chgpassvalid = false;
  errorcmessage = true;

  constructor(private api: ApiService, private toastrService: ToastrService, private route: Router) {

    this.resetPassForm = new FormGroup({
      newpass: new FormControl ('', [Validators.required,
                                    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      confnewpass: new FormControl ('', [Validators.required,
                                   Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
    });

    console.log('====> ' + window.location.search);
  }

  ngOnInit() {
  }


  checkCNewPass(e) {

    if (this.resetPassForm.value.currpass === this.resetPassForm.value.newpass){
      this.chgpassvalid = false;
    } else if (this.resetPassForm.value.newpass === this.resetPassForm.value.confnewpass){
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var checkcnewpas = e.target.value;

    if (this.resetPassForm.value.newpass === checkcnewpas) {

      this.errorcmessage = true;
    } else {
      this.errorcmessage = false;
    }
  }


  togglePasswordVisibility() {

    if (this.passwordIcon === true) {
        this.passwordIcon = false;
     }  else {
        this.passwordIcon = true;
     }
    }

    togglePasswordVisibility1() {

      if (this.passwordIcon1 === true) {
          this.passwordIcon1 = false;
       } else {
          this.passwordIcon1 = true;
       }
      }

      clicklogin() {
        this.route.navigate(['/login']);
      }

      changepassword() {

        const verificationDetails = {
          'newPassword': this.resetPassForm.value.newpass,
          'token': window.location.search
        };
        // this.api.getMethod('resetPassword?&newPassword=' + this.resetPassForm.value.newpass + '&token='+ window.location.search).subscribe(data => {

        //   if (data.Status === 'Success') {
        //      this.toastrService.success('Password Reset Successfully');
        //      this.route.navigate(['/login']);
        //    } else {
        //     this.toastrService.error(data.reason);
        //    }
        //  });

        console.log('verification ==> '+JSON.stringify(verificationDetails))
        this.api.postMethod('resetUserPassword', verificationDetails).subscribe(data => {

            if (data.Status === 'Success') {
               this.toastrService.success('Password Reset Successfully');
               this.route.navigate(['/login']);
             } else {
              this.toastrService.error(data.reason);
             }
           });
      }

}
