import * as $ from 'jquery';
import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../service/http/api.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoginService } from '../service/auth/login.service';
import { LocalStorageService } from '../service/local-storage.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  previousUrl;

  constructor(private loaderService: LoaderService, private toastr: ToastrService, private loginService: LoginService,
    private api: ApiService, public route: Router, private localStorge: LocalStorageService) {

  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var UserMappedRecord;
    try {
      UserMappedRecord = JSON.parse(this.localStorge.getLocalStorage('UserMappedRecord')).companyID;
    } catch {
      UserMappedRecord = ''
    }

    var modified = req.clone({
      setHeaders: {
        "CompanyID": btoa(UserMappedRecord || this.localStorge.getLocalStorage('ID') || ''),
        'empID': this.localStorge.getLocalStorage('empID') || '',
        'sessionKey': btoa(this.localStorge.getLocalStorage('sessionKey') || ''),
        // 'withCredentials':'true',

        "Cache-Control": "no-cache, no-store, must-revalidate",// HTTP 1.1.
        "Pragma": "no-cache",// HTTP 1.0.
        "Expires": "0" // Proxies.
      }
    });

    this.requests.push(modified);
    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(modified)
        .subscribe(

          event => {

            if (event instanceof HttpResponse) {
              this.removeRequest(modified);
              observer.next(event);
            }

          },
          err => {
            if (err.status == 786) {
              this.toastr.error("Your session has expired,as you tried logging in to system from another Machine/Browser Please relogin");
              this.removeRequest(modified);
              observer.error(err);
              sessionStorage.removeItem("ID");
              sessionStorage.clear();
              localStorage.clear();
              this.loginService.setLoginResponse(false);
              this.route.navigate(['/login']);
              this.api.setsessionKey = '';
              this.api.setEmpID = '';
              this.loginService.setNotification(true);


            } else if (err.status == 502) {
              this.toastr.error("You Are Not Allowed to Access this Data");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 229) {
              this.toastr.error("Unauthorized Access");
              this.removeRequest(modified);
              observer.error(err);
              try {
                var object = {
                  'userID': this.localStorge.getLocalStorage('empID') || ''
                }
                this.api.postMethod('newLogOutWeb', object)
                  .subscribe(response => {
                    console.log('response Log Out ' + response)
                  });
              }
              catch { }
              sessionStorage.removeItem("ID");
              sessionStorage.clear();
              localStorage.clear();
              this.loginService.setLoginResponse(false);
              this.route.navigate(['/login']);
              this.api.setsessionKey = '';
              this.api.setEmpID = '';
            } else if (err.status == 400) {
              this.toastr.error("Bad Request");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 401) {
              this.toastr.error("Unauthorized");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 402) {
              this.toastr.error("Payment Required");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 403) {
              this.toastr.error("Forbidden");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 404) {
              this.toastr.error("Not Found");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 405) {
              this.toastr.error("Method Not Allowed");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 406) {
              this.toastr.error("Not Acceptable");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 407) {
              this.toastr.error("Proxy Authentication Required");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 408) {
              this.toastr.error("Request Timeout");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 409) {
              this.toastr.error("Conflict");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 410) {
              this.toastr.error("Gone");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 411) {
              this.toastr.error("Length Required");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 412) {
              this.toastr.error("Precondition Failed");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 413) {
              this.toastr.error("Request Entity Too Large");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 414) {
              this.toastr.error("Request-url Too Long");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 415) {
              this.toastr.error("Unsupported Media Type");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 416) {
              this.toastr.error("Requested Range Not Satisfiable");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status == 417) {
              this.toastr.error("Expectation Failed");
              this.removeRequest(modified);
              observer.error(err);
            } else if (err.status === 506) {
              this.toastr.error(err.error.Message);
              this.removeRequest(modified);
              observer.error(err);
            } else {
              this.toastr.error("Server isn't responding, please try again later.");
              this.removeRequest(modified);
              observer.error(err);
            }
          },
          () => {
            this.removeRequest(modified);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(modified);
        subscription.unsubscribe();

        // For Navigation and Footer height
        if ($('#navbarheight').height() === undefined) { }
        else {
          var height = $('#navbarheight').height();
          $('#navbarheightmainframe').css('margin-bottom', height + 10 + "px");
        }
        //


        if ($('#getmainFooterHeight').height() === undefined) {

        }
        else {
          var mainFooterheight = $('#getmainFooterHeight').height();
          $('#setFootertopmargin').css('margin-bottom', mainFooterheight + 50 + "px");
        }
        // For Navigation and Footer height
      };
    });

  }
}
