import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/http/api.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../service/local-storage.service';
import { LoginService } from '../service/auth/login.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-changed-password-security',
  templateUrl: './changed-password-security.component.html',
  styleUrls: ['./changed-password-security.component.css']
})
export class ChangedPasswordSecurityComponent implements OnInit {

  changePassForm: FormGroup;

  chgpassvalid = false;
  errornmessage = true;
  errorcmessage = true;
  passwordIcon1: boolean;
  passwordIcon2: boolean;
  passwordIcon3: boolean;

  constructor(private localStorageObj: LocalStorageService, private router: Router,
    private api: ApiService, private route: Router, private toaster: ToastrService) {
    this.localStorageObj.createLocalStorage("passwordChangeSecurity", "true");

    this.changePassForm = new FormGroup({
      currpass: new FormControl('', [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      newpass: new FormControl('', [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      confnewpass: new FormControl('', [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
    });
  }

  ngOnInit() {
  }

  checkNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass) {
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass) {
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var newpas = e.target.value;

    if (this.changePassForm.value.currpass === newpas) {
      this.errornmessage = false;
    }
    else {

      this.errornmessage = true;
    }
  }

  checkCNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass) {
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass) {
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var checkcnewpas = e.target.value;

    if (this.changePassForm.value.newpass === checkcnewpas) {

      this.errorcmessage = true;
    } else {
      this.errorcmessage = false;
    }
  }

  onSubmitChangePassword() {

    const loginDetails = JSON.parse(this.localStorageObj.getLocalStorage('detail'));

    const empID: any = this.localStorageObj.getLocalStorage("empID");
    const obj = {
      'oldPassword': this.changePassForm.value.currpass,
      'newPassword': this.changePassForm.value.newpass,
      'userID': empID
    };

    this.api.postMethod('changePassword', obj).subscribe(data => {

      if (data.Status === 'Success') {
        this.toaster.success('Password Changed Successfully');
        this.localStorageObj.createLocalStorage("passwordChangeSecurity", "false");
        this.router.navigate(['/mainlogin']);
      } else {
        this.toaster.error(data.Reason);
      }
    });
  }

  togglePasswordVisibility1() {

    if (this.passwordIcon1 === true) {
      this.passwordIcon1 = false;
    } else {
      this.passwordIcon1 = true;
    }
  }

  togglePasswordVisibility2() {
    if (this.passwordIcon2 === true) {
      this.passwordIcon2 = false;
    } else {
      this.passwordIcon2 = true;
    }
  }

  togglePasswordVisibility3() {
    if (this.passwordIcon3 === true) {
      this.passwordIcon3 = false;
    } else {
      this.passwordIcon3 = true;
    }
  }

}
