import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { LocalStorageService } from '../local-storage.service';
import { Observable, Subject } from 'rxjs';
import { ServicePartnerService } from '../../user-admin/service/service-partner.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private loginResponse = new Subject<any>();
  private notification = new Subject<any>();
  listofGstin: any[] = [];

  constructor(private api: ApiService, private localStorage: LocalStorageService, private servicePartnerService: ServicePartnerService) { }
  getOTP(emailID) {
    var url = "sendemailotp?to=" + emailID;
    return this.api.getMethod(url)
  }

  callCaptcha() {
    return this.api.login('captcha');
  }

  login(formDetails: any, captchaId: any) {
    var url = 'newLoginWeb?userName=' + formDetails.username + '&password=' + formDetails.password + '&loginFrom=eFilling&captchaID='
      + captchaId + '&captchaNo=' + formDetails.captcha;
    return this.api.login(url);
  }

  newLogin(loginDetails) {
    const url = 'newLoginWeb';
    return this.api.newLogin(url, loginDetails);
  }

  checkGstin(gstinNumber) {
    var url = 'checkGstin?gstinNumber=' + gstinNumber;
    return this.api.getMethod(url);
  }

  checkMobile(mobileNumber) {
    var url = "checkMobile?mobileNumber=" + mobileNumber;
    return this.api.getMethod(url)
  }

  checkEmail(emailID) {
    var url = "checkEmail?emailID=" + emailID;
    return this.api.getMethod(url)
  }

  registration(obj): Observable<any> {
    var url = "newRegistrationWeb";
    return this.api.postMethod(url, obj)
  }

  forgotpasswordcheckemail(username) {
    var url = "checkUserNameToForgotPassword?username=" + username;
    return this.api.getMethod(url)
  }

  saveGstinsOfCompany(detail) {
    this.servicePartnerService.getGstinsOfCompany("getgstinsofcompany?companyID=" + detail.Details[0].companyID).subscribe(response => {
      this.listofGstin = response.companyGstinList;
      this.localStorage.createLocalStorage("listofGstin", JSON.stringify(this.listofGstin));
    })
  }

  saveDetails(detail, userlogin) {
    var lastlogin;
    try {
      lastlogin = detail.loginDetails[0].loginTime;
    }
    catch{
      lastlogin = null;
    }
    var detailArray = [];
    var details: any = {
      "companyID": detail.Details[0].companyID,
      "GSTNINNO": detail.Details[0].GSTNINNO,
      "companyname": detail.Details[0].companyname,
      "emailID": detail.Details[0].emailID,
      "NAME": detail.Details[0].firstName,
      "userID": detail.Details[0].userID,
      "loginTime": lastlogin,
      "userType": detail.Details[0].usertype,
      "roleId": detail.Details[0].roleId || '',
      "userSeqId": detail.Details[0].seqId,
      "newUserLoginStatus": detail.Details[0].fLoginStatus,
      "userEmailStatus": detail.Details[0].emailStatus
    };

    this.localStorage.createLocalStorage('gstin_number_for_header', JSON.stringify(detail.Details[0].GSTNINNO));
    this.localStorage.createLocalStorage('company_name_for_header', detail.Details[0].companyname);
    this.localStorage.createLocalStorage('current_login_user', userlogin.username);
    detailArray.push(details)
//    this.datepicker(detail.Details[0].companyID);
   // this.Quaterlydatepicker();
    //
   // this.vendorFinancialPeriodDatepicker(detail.Details[0].companyID);
    this.localStorage.createLocalStorage("detail", JSON.stringify(details));
    // this.localStorage.createLocalStorage("ID", JSON.stringify(detail.Details[0].companyID));//For Dev
    this.api.setEmpID= JSON.stringify(detail.Details[0].userID);
    // this.localStorage.createLocalStorage("empID", JSON.stringify(detail.Details[0].userID)); //For Dev
    this.localStorage.createLocalStorage("seqID", JSON.stringify(detail.Details[0].seqId));

  }

  saveMappedUserDetails(detail) {
    console.log(" saveMappedUserDetails    ",detail)
    var UserMappedRecord: any = {
      "companyID": detail.ho_Id,
      "GSTNINNO": detail.ho_gstin,
      "companyname": detail.ho_name,
      "RoleName": detail.role_name,
      "org_type": detail.org_type,
      "org_Hierarchy": detail.org_Hierarchy,
      "roleId": detail.role_Id || '',
      "HeaderCompanyName": detail.HeaderCompanyName,
      "HQ_Id": detail.HQ_Id || null,
      "SUB_Id": detail.SUB_Id || null,
      "STATE_Id": detail.STATE_Id || null,
      "BRANCH_ID": detail.BRANCH_ID||null,
    };

    this.localStorage.createLocalStorage("UserMappedRecord", JSON.stringify(UserMappedRecord)) //For Dev
    this.localStorage.createLocalStorage("temporaryUserMappedRecord", JSON.stringify(UserMappedRecord))
    this.localStorage.createLocalStorage("stateNameforDropDown", "null");
    this.localStorage.createLocalStorage("subNameforDropDown", "null");
    localStorage.setItem("subIDforDropDown", null);

  }

  // datepicker(event) {
  //   const url = "selectReturnMonth?&CompanyID=" + event;
  //   this.api.getMethod(url).subscribe(data => {
  //     this.localStorage.createLocalStorage("dates", JSON.stringify(data.response));
  //   })
  // }

  // Quaterlydatepicker() {
  //   const url = "selectReturnMonthQuarterly";
  //   this.api.getMethod(url).subscribe(data => {
  //     console.log("--Quaterlydatepicker->" + data)
  //     console.log("--Quaterlydatepicker--" + JSON.stringify(data.response))
  //     this.localStorage.createLocalStorage("quaterdates", JSON.stringify(data.response));
  //   })
  // }
  /// vendor
  // vendorFinancialPeriodDatepicker(companyID) {
  //   const url = "getFinancialPeriod?&CompanyID="+companyID;
  //   this.api.getMethod(url).subscribe(data => {
  //     this.localStorage.createLocalStorage("vendorFinancialYear", JSON.stringify(data.response));
  //     this.vendorFinancialMonthDatepicker(data.response[data.response.length - 1].financialPeriod);
  //      this.reconVendorWiseFinancialMonthDatepicker(data.response[data.response.length - 1].financialPeriod);
  //   });
  // }
  // vendorFinancialMonthDatepicker(year) {
  //   const url = "getReturnPeriodFromFinancialPeriod?financialPeriod=" + year;
  //   this.api.getMethod(url).subscribe(data => {
  //     console.log("--vendorFinancialMonthDatepicker->" + JSON.stringify(data))
  //     console.log("--vendorFinancialMonthDatepicker--" + JSON.stringify(data.response))
  //     this.localStorage.createLocalStorage("vendorFinancialMonth", JSON.stringify(data.response));
  //     this.localStorage.createLocalStorage("vendorFinancialMonth2", JSON.stringify(data.response));
  //     let oldDate = JSON.parse(this.localStorage.getLocalStorage("vendor-select-date"));
  //     //  if(oldDate==null){
  //     let x = new Date();
  //     let date: string = (x.getMonth() + 1).toString() + x.getFullYear().toString();
  //     if (x.getMonth() < 10) {
  //       date = '0' + date;
  //     }
  //     this.localStorage.createLocalStorage("vendor-select-date", JSON.stringify({ from: date, to: date }));
  //     console.log(JSON.stringify({ from: date, to: date }))
  //     //  }
  //   });
  // }

  // reconVendorWiseFinancialMonthDatepicker(year) {
  //   console.log('got year', year);
  //   /*
  //   function created by eknath to use in recon vendor wise module
  //   */
  //   const url = "getReturnPeriodFromFinancialPeriod?financialPeriod=" + year;
  //   this.api.getMethod(url).subscribe(data => {
  //     this.localStorage.createLocalStorage("vendorFinancialMonth", JSON.stringify(data.response));
  //     const oldDate = JSON.parse(this.localStorage.getLocalStorage("recon-vendor-wise-date"));
  //     /* if (oldDate == null) {
  //       const x = new Date();
  //       let date: string = (x.getMonth() + 1).toString() + x.getFullYear().toString();
  //       if ((x.getMonth() + 1) < 10) {
  //         date = '0' + date;
  //       }
  //       const y = (x.getFullYear() + '-' + (x.getFullYear() + 1)).toString();
  //       console.log(JSON.stringify({ from: date, to: date, year: { value: y, label: y } }),"1111111111")
  //       this.localStorage.createLocalStorage("recon-vendor-wise-date", JSON.stringify({ from: date, to: date, year: { value: y, label: y } }));
  //     } */
  //   });
  // }
  setLoginResponse(status: any) {
    this.loginResponse.next(status);
  }

  getLoginResponse(): Observable<any> {
    return this.loginResponse.asObservable();
  }
  setNotification(status: any) {
    this.notification.next(status);
  }

  getNotification(): Observable<any> {
    return this.notification.asObservable();
  }

  clearLoginResponse() {
    this.loginResponse.next();
  }
  uiTabs: any = [{
    "title": "Users Management",
    "value": "User Management",
    "icon": "../../assets/img/icons/user-mgmt.png",
    "desc": "It allows user to configure roles and map functionalities to each users."
  }, {
    "title": "Tax Filing",
    "value": "GST Filing",
    "icon": "../../assets/img/icons/gst-filling.png",
    "desc": "Dealer can file their GST return quickly."
  }, {
    "title": "Tax Calculator",
    "value": "Tax Calculator",
    "icon": "../../assets/img/icons/tax-calculator.png",
    "desc": "It simulates/calculate your GST invoice based on HSN or SAC code."
  }, {
    "title": "Search Taxpayer",
    "value": "User Management",
    "icon": "../../assets/img/icons/int-engine.png",
    "desc": "It is online utility to search your customer or vendor GST details."
  }, {
    "title": "Setting",
    "value": "Setting",
    "icon": "../../assets/img/icons/setting_icon-35.svg",
    "desc": ""
  }]
}
