import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './guard/auth.guard';
import { LocalStorageService } from './service/local-storage.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MainComponent } from './main/main.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { LoginComponent } from './login/login.component';
import { AccordionModule } from 'primeng/accordion';
import { MessageService, ContextMenuModule, AutoCompleteModule, OverlayPanelModule, DialogModule } from 'primeng/primeng';
import { TabViewModule } from 'primeng/tabview';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MainFooterComponent } from './main/main-footer/main-footer.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DatePipe } from '@angular/common';
import { FieldsetModule } from 'primeng/fieldset';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MainLoginComponent } from './user-admin/formpages/main-login/main-login.component';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { ProductPageComponent } from './user-admin/formpages/product-page/product-page.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './loader/loader.interceptors';
import { TooltipModule } from 'ngx-tooltip';
import { UndermaintenanceComponent } from './undermaintenance/undermaintenance.component';
import { Idle } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangepassComponent } from './changepass/changepass.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ExpirePlanComponent } from './expire-plan/expire-plan.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { EmailverificationComponent } from './emailverification/emailverification.component';
import { UserActivationPageComponent } from './user-activation-page/user-activation-page.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import {MessageModule} from 'primeng/message';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HideProdDirective } from './hide-prod.directive';
import { ComingSoonTabComponent } from './coming-soon-tab/coming-soon-tab.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChangedPasswordSecurityComponent } from './changed-password-security/changed-password-security.component';
// import { DepartmentUserCreationComponent } from './user-admin/formpages/department-user-creation/department-user-creation.component';
//import { DepartmentOrgCreationComponent } from './user-admin/formpages/department-org-creation/department-org-creation.component';
//import { CompanyOrgMappingComponent } from './user-admin/formpages/company-org-mapping/company-org-mapping.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MainComponent,
    MainLoginComponent,
    ProductPageComponent,
    MainFooterComponent,
    LoaderComponent,
    UndermaintenanceComponent,
    ChangepassComponent,
    ComingSoonComponent,
    ExpirePlanComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    EmailverificationComponent,
    UserActivationPageComponent,
    UserAgreementComponent,
    HideProdDirective,
    ComingSoonTabComponent,
    ChangedPasswordSecurityComponent,
    // DepartmentUserCreationComponent,
    //DepartmentOrgCreationComponent,
    //CompanyOrgMappingComponent,
    
    
   
    
    
    
    
        
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    DialogModule,
    ToastrModule.forRoot(
      {
        timeOut: 3000,
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        progressAnimation: "increasing",
        progressBar: true,
        extendedTimeOut: 1000,
        easeTime: 500,
        positionClass: 'toast-top-right',
      }
    ),
    NgSelectModule,
    TabsModule.forRoot(),
    TableModule,
    ButtonModule,
    PaginatorModule,
    ModalModule.forRoot(),
    AccordionModule,
    TabViewModule,
    AlertModule.forRoot(),
    AutoCompleteModule,
    ContextMenuModule,
    FieldsetModule,
    ScrollPanelModule,
    TabViewModule,
    SidebarModule,
    OverlayPanelModule,
    SliderModule,
    KeyFilterModule,
    NgxPaginationModule,
    Ng2OrderModule,
    Ng2SearchPipeModule,
    TooltipModule,
    NgIdleKeepaliveModule.forRoot(),
    MessageModule,
    BsDatepickerModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    LocalStorageService,
    MessageService,
    DatePipe,
    Idle,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService}
  ],


  bootstrap: [AppComponent]
})
export class AppModule { }
