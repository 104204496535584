// import { url } from 'inspector';
/* This file can be replaced during build by using the `fileReplacements` array.
    `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
     The list of file replacements can be found in `angular.json`. */

export const environment = {
  production: false,
// baseUrl: 'http://localhost:8080/buyerAdmin-0.0.1/',

  // dev
  // baseUrl: 'http://65.0.10.203:8080/buyerAdmin-0.0.1/',

  //test
  // baseUrl:'http://3.7.165.99:8080/buyerAdmin-0.0.1/'

  // demo
// baseUrl:'http://15.207.228.131:8080/buyerAdmin-0.0.1/',
  // baseUrl1: 'http://15.207.228.131:9000/PayInvoice_v2/'

  // prod
  // baseUrl:'https://buyeradmin.taxgenie.online/buyerAdmin-0.0.1/',

    // prodABFL
    // baseUrl: 'https://payinvoice-admin.abfldirect.com/buyerAdmin-0.0.1/',
    // baseUrl1: 'https://payinvoice-admin.abfldirect.com/payinvoice/'

   baseUrl: 'https://tg-buyeradmin.taxgenie.online/nttbuyerAdmin-0.0.1/',
   baseUrl1: 'https://tg-buyer.taxgenie.online/ntt/'


}