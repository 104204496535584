import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { Subject, Observable, Subscription} from 'rxjs';
import { DatePipe } from '@angular/common';
import { shareReplay, takeUntil, share, tap, publish, refCount, multicast, publishReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  empID  = new Subject<any>();
  sessionKey  = new Subject<any>();
  baseUrl : any = environment.baseUrl;
  baseUrl1: any = environment.baseUrl;
  InvoicResponseSubject = new Subject<any>();
  responseSubject = new Subject<any>();
  responseSubjectMulti = new Subject<any>();
  defaultFrame = new Subject<any>();
  mainFooter = new Subject<any>();
  submenu = new Subject<any>();
  empId: any;
 
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
       'empID': this.localStorge.getLocalStorage('empID') || '',
       'sessionKey': this.localStorge.getLocalStorage('sessionKey') || ''
    })
  };  

   HttpUploadOptions = {
    headers: new HttpHeaders({'Content-Type': 'multipart/form-data'})
  }
  set setEmpID(value){
   
    this.localStorge.createLocalStorage('empID',value);
    this.empID.next(value);
  }

  get getEmpID(){
    return this.empID.asObservable();
  }
  
  set setsessionKey(value){
   
    this.localStorge.createLocalStorage('sessionKey',value);
    this.sessionKey.next(value);
  }
  get getsessionKey(){
    return this.sessionKey.asObservable();
  }
  constructor(private _http: HttpClient, private localStorge: LocalStorageService, private datePipe: DatePipe) {
    }

    login(url: any): Observable<any> {
      const api = this.baseUrl.concat(url);
      return this._http.get<any>(api).pipe(share());
    }

    newLogin(url: any, data): Observable<any> {
      const api = this.baseUrl.concat(url);
      return this._http.post(api, data, this.httpOptions).pipe(share());
    }
  getMethod(url: any): Observable<any> {
    const api = this.baseUrl.concat(url);
    return this._http.get<any>(api, this.httpOptions).pipe(share());
  }

  // getMethod(url: any): Observable<any> {
  //   const api = this.baseUrl.concat(url);
  //   return this._http.get(api, { responseType: 'blob' });
  // }

  getMethodEInvoice(url: any): Observable<any> {

  const api = this.baseUrl.concat(url);
  return this._http.get(api, { responseType: 'blob' }).pipe(share());
  }
  postMethodGovt(url: any, data ?: any): Observable<any> {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data, this.httpOptions).pipe(share());
  }
 
  getMethod2(url: any, data?: any): Observable<any> {
    var url2 = url.concat(data);
    const api = this.baseUrl.concat(url2);
    return this._http.get<any>(api, data).pipe(share());
  }
  postMethod(url: any, data ?: any): Observable<any> {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data, this.httpOptions).pipe(share());
  }


  postUpload(url: any, data ?: any) {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data, { observe: 'response'}).pipe(share());
  }

  postUploadForEmail(url: any, data ?: any): Observable<any>  {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data).pipe(share());
  }
  postUploadNotice(url: any, obj: any) {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, obj, { observe: 'response'}).pipe(share());
  }

  putMethod(url: any, data ?: any) {
    const api = this.baseUrl.concat(url);
    return this._http.put(api, data, this.httpOptions).pipe(share());
  }

    putMethod1(url: any) {
    const api = this.baseUrl.concat(url);
    return this._http.put(api, this.httpOptions).pipe(share());
  }
    postMethod1(url: any) {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, this.httpOptions).pipe(share());
 }

 get1(url: any) {
   const HTTPOptions = {
     headers: new HttpHeaders({
        'Accept': 'application/pdf',
        'empID': this.localStorge.getLocalStorage('empID') || ''
     }),
     'responseType': 'application/pdf'
  };

  const api = this.baseUrl.concat(url);
  return this._http.get(api, { responseType: 'blob' }).pipe(share());
 }

  formatNumber(data) {
    // style: 'currency', for rupess sign
    if (data === '' || data === undefined) {
      return '';
    } else if (isNaN(data)) {
      return '';
    }
    const fromated = Number(data).toLocaleString('en-IN', {  currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2});
    return fromated;
  }

  removeComma(data) {
    const filter = data.replace(/\,/g, '');
    return filter;
  }

  get2AService(url): Observable<any> {
    const urlpath = this.baseUrl.concat(url);
    return this._http.get<any>(urlpath, { observe: 'response'}).pipe(share());
  }

  setRespone(data) {
    this.responseSubject.next(data);
  }
  getResponse(): Observable<any> {
    return this.responseSubject.asObservable();
  }
  setInvoiceReponse(data) {
    this.InvoicResponseSubject.next(data);
  }

  getInvoiceResponse(): Observable<any> {
    return this.InvoicResponseSubject.asObservable();
  }

  setResponeMulti(data) {
    this.responseSubjectMulti.next(data);
  }
  getResponseMulti(): Observable<any> {
    return this.responseSubjectMulti.asObservable();
  }

  setDefaultFrame(data: any) {
    this.defaultFrame.next(data);
  }
  getDefaultFrame(): Observable<any> {
    return this.defaultFrame.asObservable();
  }

  setMainFrameFooter(data: any) {
    this.mainFooter.next(data);
  }
  getMainFrameFooter(): Observable<any> {
    return this.mainFooter.asObservable();
  }

  set setsubmenudata(data: any) {
    this.submenu.next(data);
  }
  get getsubmenudata(): Observable<any> {
    return this.submenu.asObservable();
  }
  transferDate(data, format) {
    return this.datePipe.transform(data, format);
  }

 getHeader(url,invoiceType): Observable<any>{
    const api = this.baseUrl1.concat(url);
    return this._http.post(api,invoiceType).pipe(share());
  }
  postService(url:any, data?:any): Observable<any>{
    const api = this.baseUrl1.concat(url);
    return this._http.post(api, data).pipe(share());
  }

  uploadtempt(url:any, data?:any, data1?: any): Observable<any>{
    const api = this.baseUrl1.concat(url);
    return this._http.post(api, data, data1).pipe(share());
  }

  fileDownloader(url, data): Observable<any>{
    const api = this.baseUrl1.concat(url);
    return this._http.post(api, data,{responseType: 'blob'}).pipe(share());
  }
  postMethod3(url: any, data :any){
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data );
  }

  getPDF(url: any, data ?: any): Observable<any> {
    const api = this.baseUrl.concat(url);
    return this._http.post(api, data,{responseType: 'blob' as 'json'}).pipe(share());
  }
  // getPDF(){
    
  //   const url = `${this.baseUrl1}/pdf`;
    
  //   const httpOptions = {
  //     'responseType'  : 'arraybuffer' as 'json'
  //      //'responseType'  : 'blob' as 'json'        //This also worked
  //   };
    
  //   return this._http.get<any>(this.baseUrl1, httpOptions);
    
  //   }



  stateDetails = [{value: "35",label: "Andaman and Nicobar Islands", disable : true},{value: "28",label: "Andhra Pradesh"},{value: "37",
  label: "Andhra Pradesh (New)"},{value: "12",label: "Arunachal Pradesh "},{value: "18",label: "Assam"},{value: "10",label: "Bihar"},
  {value: "04",label: "Chandigarh"},{value: "22",label: "Chattisgarh"},{value: "26",label: "Dadra and Nagar Haveli"},
  {value: "25",label: "Daman and Diu"},{value: "07",label: "Delhi"},{value: "30",label: "Goa"},{value: "24",label: "Gujarat"},{value: "06",label:
  "Haryana"},{value: "02",label: "Himachal Pradesh"},{value: "01",label: "Jammu and Kashmir"},{value: "20",label: "Jharkhand"},
  {value: "29",label: "Karnataka"},{value: "32",label: "Kerala"},{value: "31",label: "Lakshadweep Islands"},{value: "23",label: "Madhya Pradesh"},
  {value: "27",label: "Maharashtra"},{value: "14",label: "Manipur"},{value: "17",label: "Meghalaya"},{value: "15",label: "Mizoram"},
  {value: "13",label: "Nagaland"},{value: "21",label: "Odisha"},{value: "34",label: "Pondicherry"},{value: "03",label: "Punjab"},
  {value: "08",label: "Rajasthan"},{value: "11",label: "Sikkim"},{value: "33",label: "Tamil Nadu"},{value: "36",label: "Telangana"},
  {value: "16",label: "Tripura"},{value: "09",label: "Uttar Pradesh"},{value: "05",label: "Uttarakhand"},{value: "19",label: "West Bengal"}
  ];
}
