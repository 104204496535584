import { Component, HostListener, } from '@angular/core';
import { LoginService } from './service/auth/login.service';
import { Subscription } from 'rxjs'
import { LocalStorageService } from './service/local-storage.service';
import { RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
declare var $: any;
//import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { LoaderService } from './loader/loader.service';
import { ApiService } from './service/http/api.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  loginStaus: boolean = false;
  subscriptin: Subscription;
  mainFrameStatus: boolean = false;
  idleState = 'Not started.';
  idleState2 = 'started';
  timedOut = false;
  lastPing?: Date = null;
  timeLeft: number = 60;
  interval;
  loading: boolean;


  public isShowingRouteLoadIndicator: boolean = true;
  constructor(private api: ApiService,private loginService: LoginService, private crypto: LocalStorageService,
     private router: Router, private idle: Idle, 
    private loaderService: LoaderService) {
    localStorage.setItem("timerForLogout","false")
    const l_page: any = this.crypto.getLocalStorage('l_page');
    const resetpassword: any = this.crypto.getLocalStorage('resetpassword');
    const expirePlan: any = this.crypto.getLocalStorage('expirePlan');
    const authUser: any = this.crypto.getLocalStorage('ID');
    const emailVefStatus: any = this.crypto.getLocalStorage('emailVerificationStatus');
    const userAgreement: any = this.crypto.getLocalStorage('userAgreements');
    const changedpasswordSecurity: any = this.crypto.getLocalStorage('passwordChangeSecurity');

    console.log(' changedpasswordSecurity => ', changedpasswordSecurity);

    if (authUser != null) {

      if (l_page === 'true') {

        router.navigate(['/productpage']);

      } else if (resetpassword === 'true') {

        router.navigate(['/changepassword']);

      } else if (expirePlan === 'true') {

        router.navigate(['/expirePlan']);

      } else if (emailVefStatus === 'true') {

        router.navigate(['/emailverification']);

      } else if (userAgreement === 'true') {

        router.navigate(['/useragreement']);

      } else if (changedpasswordSecurity === 'true'){

        router.navigate(['/passwordSecurity']);
        console.log(' under passwod security component ');
      }
       else {
        router.navigate(['/mainlogin']);
      }

    }
    var asyncLoadCount = 0;
    router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        }

        // If there is at least one pending asynchronous config load request,
        // then let's show the loading indicator.
        // --
        // CAUTION: I'm using CSS to include a small delay such that this loading
        // indicator won't be seen by people with sufficiently fast connections.
        this.isShowingRouteLoadIndicator = !!asyncLoadCount;

      }
    );

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.subscriptin = this.loginService.getLoginResponse().subscribe(data => {
      if (data) {
        this.loginStaus = true;
      } else {
        this.loginStaus = false;
        const authUser: any = this.crypto.getLocalStorage('ID');
        if (authUser != null) {
          this.mainFrameStatus = true;
        } else {
          this.mainFrameStatus = false;
          sessionStorage.removeItem("ID");
          sessionStorage.clear();
          localStorage.clear();
          this.api.setsessionKey='';
          this.api.setEmpID='';
        }
      }
    })

    //autologout
    // // sets an idle timeout of 5 seconds, for testing purposes.
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer Logged In.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      const authUser: any = this.crypto.getLocalStorage('ID');
      const timeForLogout= localStorage.getItem("timerForLogout");
      if (authUser === null || timeForLogout==="true") {
        this.idleState2 = 'started';
        this.reset();
        this.reset2();
      }
      else {
        this.loaderService.isLoading.subscribe((v) => {
          this.loading = v;
        });
        if (this.idleState === 'Timed out!' && this.isShowingRouteLoadIndicator === false && this.loading === false) {
          this.idleState2 = 'Timed out!';
          this.timeLeft = 60;
          this.startTimer();
        }
      }
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = countdown);
    this.reset();
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
      else {
        clearInterval(this.interval);
      }

      if (this.timeLeft === 0) {
        this.logout();
      }

    }, 1000);
  }
  // onmousemove,mousedown,mouseup,onkeydown,onkeyup,focus

  /* @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:focus', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:mouseup', ['$event'])
*/
  reset() {
    console.log('in reset');
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  reset2() {
    console.log('in reset 2');
    this.idleState2 = 'Started.';
    this.timeLeft = 60;
    clearInterval(this.interval);
  }

  logout() {
    try {
      var object = {
        'userID': this.crypto.getLocalStorage('empID') || ''
      }
      this.api.postMethod('newLogOutWeb' , object)
        .subscribe(response => {
          console.log('response Log Out ' + response)
        });
    }
    catch{ }
    this.idleState2 = 'started';
    sessionStorage.removeItem("ID");
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.router.navigate(['/login'])
    this.api.setsessionKey='';
    this.api.setEmpID='';
  }
  //Scroll to top on every Route click
  onDeactivate() {
    document.body.scrollTop = 0;
  }

  /* //unloaded
  @HostListener('window:unload', [ '$event' ])
  public unloadHandler($eventunloaded) {
    $eventunloaded.returnValue = "Are you sure?";
    console.log("unloaded");
  }

  //leave and reload
  @HostListener('window:beforeunload', [ '$event' ])
  public beforeUnloadHander($eventleave) {
    $eventleave.returnValue = "Are you sure?";
    console.log("leave");
  }

  //back
  @HostListener('window:popstate', ['$event'])
  public onPopState($eventback) {
    $eventback.returnValue = "Are you sure?";
    console.log("popstate");
  } */
}
