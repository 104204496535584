import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-activation-page',
  templateUrl: './user-activation-page.component.html',
  styleUrls: ['./user-activation-page.component.css']
})
export class UserActivationPageComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  goOnLoginPage() {
    this.route.navigate(['/login'])
  }
}
