import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../service/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  @ViewChild('checkmail')
  checkmail: ModalDirective;

  @ViewChild('errorMsg')
  errorMsg: ModalDirective; 

  myEmail: any;
  Email_ID: any;
  registeredEmailId: any;
  errorWarning:any
  
  checkforgotpassword: FormGroup;

  constructor(private loginService: LoginService, private toastrService: ToastrService, private route: Router) {

    this.checkforgotpassword = new FormGroup({
     // emailid: new FormControl({ value: '', disabled: false }, Validators.compose([Validators.required, Validators.email])),
      userName: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {
  }

  checkemailid(e) {
    this.loginService.forgotpasswordcheckemail(e.userName).subscribe(data => {

      console.log('mail response ==> '+JSON.stringify(data));
      this.registeredEmailId = data.EmailId;
      this.errorWarning = data.Reason
      console.log('===========>'+this.errorWarning)
      if (data.Status == 'Fail') {
        this.errorMsg.show();
        setTimeout(() => {
          this.checkmail.hide();
        }, 10000);
        //this.toastrService.error(data.Reason);
      }

      if (data.Status == 'Success') {

        this.checkmail.show();
        // this.toastrService.success('We have sent you a link on your registered Email-Id which is '+data.EmailId+' , If it doesn’t appear within a few minutes, check your spam folder.');
        // this.checkmail.show();
        setTimeout(() => {
          this.checkmail.hide();
        }, 12000);
      }
    })
  }

  clicklogin() {
    this.route.navigate(['\login']);
  }

  hideModelPopup(){
    this.checkmail.hide();
  }

  hideErrorModelPopup(){
    this.errorMsg.hide();
  }

}
