import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { LoginService } from '../service/auth/login.service';
import { ApiService } from '../service/http/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  displaypopup = false;
  showEmailComposePopup = false;
  showSMSComposePopup = false;
  constructor(private loaderService: LoaderService, private loginService: LoginService, private api: ApiService, public route: Router,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });

    this.loginService.getNotification().subscribe((v) => {
      this.displaypopup = v;
    });

   
  }
  ngOnInit() { }
  logOut() { }

  
}
