import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../service/http/api.service';
import { LocalStorageService } from '../../../service/local-storage.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TgAdminService } from '../Service/tg-admin.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/service/companylist/company.service';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {

  @ViewChild('alertPopup')
  alertPopup: ModalDirective;

  productData = [];
  tgProduct = [];
  homeButton = new Subscription();
  subinvoice = new Subscription();
  licensedProdDiv = true;
  unLicensedProdDiv = false;
  ComingSoon = false;

  demoProdDiv = false;
  licensedClass: any = 'active';
  unLicensedClass: any = 'not';
  demoClass: any = 'not';
  comingSoonProductList = [];
  tgProductType = [
    {'name':'Subscribed Products', 'value':'1'},
    {'name':'Unsubscribed Products', 'value':'2'},
    // {'name':'Coming Soon', 'value':'3'}
  ];

  homeStatus = false;
  constructor(private api: ApiService, private localStorageObj: LocalStorageService,  private toastrObj: ToastrService,
              private route: Router, private companyService: CompanyService) {
                  localStorage.setItem('homeTagStatus', 'true');
    const  UserMappedRecord = JSON.parse(this.localStorageObj.getLocalStorage('temporaryUserMappedRecord'));
    const UserMappedRecorddata: any = {
      'companyID': UserMappedRecord.companyID,
      'GSTNINNO' : UserMappedRecord.GSTNINNO,
      'companyname' : UserMappedRecord.companyname,
      'RoleName' : UserMappedRecord.RoleName,
      'org_type' : UserMappedRecord.org_type,
      'org_Hierarchy': UserMappedRecord.org_Hierarchy,
      'roleId': UserMappedRecord.roleId || '',
      'HeaderCompanyName': UserMappedRecord.HeaderCompanyName,
      'HQ_Id': UserMappedRecord.HQ_Id || null,
      'SUB_Id': UserMappedRecord.SUB_Id || null,
      'STATE_Id': UserMappedRecord.STATE_Id || null,
      "BRANCH_ID": UserMappedRecord.BRANCH_ID||null,
      };

    var loginFromSupplierPortal = this.localStorageObj.getLocalStorage("loginFromSupplierPortal");
    if (loginFromSupplierPortal == "true") {
      UserMappedRecorddata.roleId="217";
    }

    this.localStorageObj.createLocalStorage('UserMappedRecord', JSON.stringify(UserMappedRecorddata));
    this.localStorageObj.createLocalStorage('stateNameforDropDown', 'null');
    this.localStorageObj.createLocalStorage('subNameforDropDown', 'null');
    localStorage.setItem('subIDforDropDown', null);
    // code by nisha
    localStorage.removeItem('SubCompany');
    localStorage.removeItem('StateCompany');
    localStorage.removeItem('branchCompany');
    localStorage.removeItem('levelId');
    localStorage.removeItem('sendTempFinYear');
    localStorage.removeItem('sendTempLimit');

    // this.subinvoice = this.companyService.getProductStatus().subscribe(data => {
    //  console.log('Product page status ==> ' + data);
    //  if (data === 'Licensed Product') {
    //    this.licensedProdDiv = true;
    //    this.unLicensedProdDiv = false;
    //  } else if (data === 'Unlicensed Product') {
    //    this.unLicensedProdDiv = true;
    //   this.licensedProdDiv = false;
    //  } else if (data === 'Demo Product') {

    //  }
    // });

    this.licensedClass="1";
  }

  ngOnInit() {
    this.getProduct();
    this.getAllTgProduct();
  }

  getProduct() {
    const UserMappedRecord = JSON.parse(this.localStorageObj.getLocalStorage('UserMappedRecord'));
    console.log("UserMappedRecord   ",UserMappedRecord)
    this.api.getMethod('getproduct?roleId=' + this.localStorageObj.getLocalStorage('roleId'))
    .subscribe(data => {
      this.productData = data.productList;
    });
  }

  getMenus(tab) {

    console.log(" tab tab => ", tab);

    this.localStorageObj.createLocalStorage('productkey', JSON.stringify(tab));
    this.route.navigate(['/products']);
  }

  getAllTgProduct() {
    this.api.getMethod('getAllTgProductList').subscribe(data => {
      this.tgProduct = [];
      this.comingSoonProductList = [];
      for (let i = 0; i < data.productList.length; i++) {

        if (data.productList[i].ParentId === 0 && data.productList[i].ProductId !== 1 && data.productList[i].ProductId !== 195
           && data.productList[i].ProductId !== 227 && data.productList[i].ProductId !== 239
            && data.productList[i].ProductId !== 254 && data.productList[i].ProductId !== 290
            && data.productList[i].ProductId !== 709 && data.productList[i].ProductId !== 717
            && data.productList[i].ProductId !== 719 ) {

          this.tgProduct.push(data.productList[i]);
        }

        if(data.productList[i].coming_soon === 1){
          this.comingSoonProductList.push(data.productList[i]);
        }
      }

      for (let k = 0; k < this.productData.length; k++) {

        for (let j = 0; j < this.tgProduct.length; j++) {

        if (this.tgProduct[j].ProductId === this.productData[k].ProductId) {

          this.tgProduct.splice(j, 1);
        } 
        }
      }

    });
  }

  unlicensedProdWarning() {
    this.alertPopup.show();
    setTimeout(() => {
      this.alertPopup.hide();
    }, 5000);
  }

  hideModelPopup() {
    this.alertPopup.hide();
  }

  getSubMenu(data) {
    
    if (data === "1") {
      this.licensedClass = "1";
      this.licensedProdDiv = true;
      this.unLicensedProdDiv = false;
      this.ComingSoon = false;
      this.demoProdDiv = false;
    }

    else if (data === "2") {
      this.unLicensedProdDiv = true;
      this.licensedProdDiv = false;
      this.ComingSoon = false;
      this.licensedClass = "2";
      this.demoProdDiv = false;
    }

    else if (data === "3"){
      this.ComingSoon = true;
      this.unLicensedProdDiv = false;
      this.licensedProdDiv = false;
      this.licensedClass = "3";
      this.demoProdDiv = false;
    }
  }

  
  getWidthsubscribeandunsubscribeproduct(){
    let width = 100/this.tgProductType.length;
    return width+'%';
  }

  heading: any;

  comingSoonMethod(event) {

    if (event.prodname_a == event.prodname_a) {
      try {
        this.heading = event.prodname_a;
        this.localStorageObj.createLocalStorage('commingsoon', this.heading);  
      } catch (error) {
        this.localStorageObj.createLocalStorage('commingsoon', ''); 
      }
    }
    this.route.navigate(['/comingSoonTab']);
  }
}
