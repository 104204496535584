import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/auth/login.service';
import { LocalStorageService } from '../service/local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from '../service/http/api.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit {

  checked: boolean = false;
  submitButtonStatus = true;

  constructor(private loginService: LoginService,private localStorageObj : LocalStorageService,private router : Router,
     private api: ApiService,  private route: Router) { 
    this.localStorageObj.createLocalStorage("userAgreements", "true");

   }

  ngOnInit() {
  }
  logout(){
    try {
      var object = {
        'userID': this.localStorageObj.getLocalStorage('empID') || ''
      }
      this.api.postMethod('newLogOutWeb' , object)
        .subscribe(response => {
          console.log('response Log Out ' + response)
        });
    }
    catch{ }
    sessionStorage.removeItem("ID");
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.router.navigate(['/login'])
    this.api.setsessionKey='';
    this.api.setEmpID='';
  }

  checkboxStatus(event) {

    if (event.target.checked === true) {
      this.submitButtonStatus = false;
  } else {
    this.submitButtonStatus = true;
  }
  }

  changeAgreementStatus() {

    const loginDetails = JSON.parse(this.localStorageObj.getLocalStorage('detail'));

    console.log('user data => '+JSON.stringify(loginDetails));
    console.log('login status = '+loginDetails.newUserLoginStatus);
    this.api.getMethod('updateAgreementStatus?userId='+loginDetails.userID).subscribe(response => {

      if(response.Status === "Success") {
        // this.toastrService.success('Success!! You have successful login');
        this.localStorageObj.createLocalStorage("userAgreements", "false");

        if(loginDetails.newUserLoginStatus === 'Yes') {

          this.route.navigate(['/changepassword']);

        } else if(loginDetails.userEmailStatus === 0) {

          this.route.navigate(['/emailverification']);

        } else {
          this.router.navigate(['/mainlogin']);
        }
        
      } else {

      }

    })
  }


}
