import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LoginService } from 'src/app/service/auth/login.service';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from '../../../service/local-storage.service';
import { DefaultRoleMasterService } from '../../service/default-role-master.service';
import { ApiService } from '../../../service/http/api.service';
import { ShareProductService } from '../../service/share-product.service';
import { TgAdminService } from '../Service/tg-admin.service';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/service/companylist/company.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.css']
})
export class MainLoginComponent implements OnInit, AfterViewInit {
  cols1: any[];
  demoArray = [];
  userRecord = [];
  logindetails: any;
  mainDiv: boolean = false;
  sceondDiv: boolean = true;
  productData = [];
  gstin_number: any;
  state: any;
  subscriptin: Subscription;
  // expireDays:boolean=false;
  getExpireDays: any;
  planDays: any;
  popupCount: any = 0;
  spEmailId: any;
  spcontactPersonName: any;
  spcontactPersonNumber: any;

  service: boolean = false;

  @ViewChild('passwordSecurity')
  passwordSecurity: ModalDirective;

  changePassForm: FormGroup;

  getChangedPasswordSecurityLocalStorage: any;

  @ViewChild('goingtoplanisexpiredpopup') goingtoplanisexpiredpopup: ModalDirective;
  constructor(private loginService: LoginService, private route: Router, private localStorageObj: LocalStorageService,
    private roleMasterSeviceObj: DefaultRoleMasterService, private api: ApiService, public shareServiceObj: ShareProductService,
    private tgAdminServiceObj: TgAdminService, private companyService: CompanyService, private toaster: ToastrService) {

    var loginFromSupplierPortal = this.localStorageObj.getLocalStorage("loginFromSupplierPortal");
    if (loginFromSupplierPortal == "true") {
      this.logindetails = this.localStorageObj.getLocalStorage("detail");
      this.logindetails = JSON.parse(this.logindetails);``
      this.getUserMappingRecord(this.logindetails.userSeqId);   
    }
    else {
      /* THIS CODE FOR EXPIRE PLAN POPUP */
      // this.spEmailId = localStorage.getItem("spEmailId");
      // this.spcontactPersonName = localStorage.getItem("spcontactPersonName");
      // this.spcontactPersonNumber = localStorage.getItem("spcontactPersonNumber");
      this.popupCount = this.localStorageObj.getLocalStorage("expirePopupCount");
      this.spEmailId = this.localStorageObj.getLocalStorage("spEmailId");
      this.spcontactPersonName = this.localStorageObj.getLocalStorage("spcontactPersonName");
      this.spcontactPersonNumber = this.localStorageObj.getLocalStorage("spcontactPersonNumber");
      console.log(this.spEmailId, "<<<-1->>>", this.spcontactPersonName, "<<<-2->>>", this.spcontactPersonNumber)
      if ((this.spEmailId == undefined || this.spEmailId == 0 || this.spEmailId == null) &&
        (this.spcontactPersonName == undefined || this.spcontactPersonName == 0 || this.spcontactPersonName == null) &&
        (this.spcontactPersonNumber == undefined || this.spcontactPersonNumber == 0 || this.spcontactPersonNumber == null)) {
        this.service = true;
      } else {
        this.service = false;
      }
      /* /THIS CODE FOR EXPIRE PLAN POPUP */

      //  this.getExpireDays=localStorage.getItem("expirePlanDays");
      this.getExpireDays = this.localStorageObj.getLocalStorage("expirePlanDays");

      this.localStorageObj.createLocalStorage("l_page", "false");
      // this.loginService.setLoginResponse(true);

      //this.tgAdminServiceObj.setDisplayLoginCompany(false);
      var UserMappedRecord = JSON.parse(this.localStorageObj.getLocalStorage("UserMappedRecord"));
      if (UserMappedRecord != null && UserMappedRecord.GSTNINNO != null) {

        this.gstin_number = UserMappedRecord.GSTNINNO;
        var stateCode = this.gstin_number.substring(0, 2)
        // this.state = this.stateCodeObj.getStateCode(stateCode);

      }

      this.cols1 = [
        { field: 'ho_name', header: 'Organisation Name', width: '150px' },
        { field: 'subsidary_name', header: 'Subsidiary Name', width: '150px' },
        { field: 'state', header: 'State', width: '150px' },
        { field: 'ho_gstin', header: 'GSTIN', width: '150px' },
        { field: 'org_type', header: 'Organisation Type', width: '150px' },
        { field: 'org_Hierarchy', header: 'Organisation Hierarchy', width: '150px' },
        { field: 'role_name', header: 'Role', width: '150px' },
        { field: 'Process', header: 'Process', width: '200px' }
      ]

      this.logindetails = this.localStorageObj.getLocalStorage("detail");
      this.logindetails = JSON.parse(this.logindetails);
      this.getUserMappingRecord(this.logindetails.userSeqId);


      this.changePassForm = new FormGroup({
        currpass: new FormControl('', [Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
        newpass: new FormControl('', [Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
        confnewpass: new FormControl('', [Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
      });
    }

  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('this.getExpireDays', this.getExpireDays);
    if (this.getExpireDays <= 30 && this.popupCount == 0) {
      // this.expireDays=true;
      this.goingtoplanisexpiredpopup.show();
      this.localStorageObj.createLocalStorage("expirePopupCount", "1");
    }
    else {
      // this.expireDays=false;
    }

    this.getChangedPasswordSecurityLocalStorage = this.localStorageObj.getLocalStorage("ChangedPasswordSecurity");
    // if(this.getChangedPasswordSecurityLocalStorage < 0 && this.getChangedPasswordSecurityLocalStorage == 0 && this.getChangedPasswordSecurityLocalStorage > 5){
    //   this.passwordSecurity.hide();
    //   localStorage.removeItem("ChangedPasswordSecurity");
    // } else if(this.getChangedPasswordSecurityLocalStorage > 0 && this.getChangedPasswordSecurityLocalStorage < 5 && this.getChangedPasswordSecurityLocalStorage == 5){
    //   this.passwordSecurity.show();
    //   localStorage.removeItem("ChangedPasswordSecurity");
    // } else {
    //   this.passwordSecurity.hide();
    // }

    if (this.getChangedPasswordSecurityLocalStorage >= 1 && this.getChangedPasswordSecurityLocalStorage <= 5) {
      this.passwordSecurity.show(); // show
      console.log(' if condition ', this.getChangedPasswordSecurityLocalStorage);
    } else if (this.getChangedPasswordSecurityLocalStorage <= 0 && this.getChangedPasswordSecurityLocalStorage == 0) {
      this.passwordSecurity.hide(); // hide
      console.log(' 1 else if  ', this.getChangedPasswordSecurityLocalStorage);
    } else if (this.getChangedPasswordSecurityLocalStorage < 0) {
      this.passwordSecurity.hide(); // hide
      console.log(' 2 else if ', this.getChangedPasswordSecurityLocalStorage);
    } else {
      this.passwordSecurity.hide(); // hide
      console.log(' else ', this.getChangedPasswordSecurityLocalStorage);
    }
  }

  selectRole(data) {
    var companyID;

    var companyname;
    var headercompanyname;
    var ho_Id = null;
    var subsidary_Id = null;
    var state_Id = null;
    var branch_Id = null;

    if (data.subsidary_Id == 0 && data.state_Id == 0 && data.branch_Id == 0) {
      companyID = data.ho_Id;
      companyname = data.ho_name;
      headercompanyname = data.ho_name;
      ho_Id = data.ho_Id
    } else if (data.state_Id == 0 && data.branch_Id == 0) {
      companyID = data.subsidary_Id;
      companyname = data.subsidary_name;
      headercompanyname = data.subsidary_name;
      subsidary_Id = data.subsidary_Id;
      ho_Id = data.ho_Id
    } else if (data.branch_Id == 0) {
      companyID = data.state_Id;
      companyname = data.state_name;
      headercompanyname = data.subsidary_name;
      state_Id = data.state_Id;
      subsidary_Id = data.subsidary_Id;
      ho_Id = data.ho_Id
    } else {
      companyID = data.branch_Id;
      companyname = data.branch_name;
      headercompanyname = data.subsidary_name;
      branch_Id = data.branch_Id;
      state_Id = data.state_Id;
      subsidary_Id = data.subsidary_Id;
      ho_Id = data.ho_Id
    }


    var DATA: any = {
      "ho_Id": companyID,
      "ho_gstin": data.ho_gstin,
      "ho_name": companyname,
      "role_name": data.role_name,
      "org_type": data.org_type,
      "org_Hierarchy": data.org_Hierarchy,
      "role_Id": data.role_Id || '',
      "HeaderCompanyName": headercompanyname,
      "HQ_Id": ho_Id || null,
      "SUB_Id": subsidary_Id || null,
      "STATE_Id": state_Id || null,
      "BRANCH_ID": branch_Id || null,
    };

    var loginFromSupplierPortal = this.localStorageObj.getLocalStorage("loginFromSupplierPortal");
    if (loginFromSupplierPortal == "true") {
      DATA.role_Id="217";
      this.localStorageObj.createLocalStorage("roleId", "217")
    }
    else{
      this.localStorageObj.createLocalStorage("roleId", data.role_Id)
    }
    this.companyService.setHideProductNavigation(true);
    this.localStorageObj.createLocalStorage("WorkOnThisData", JSON.stringify(DATA));
    this.localStorageObj.createLocalStorage("l_page", "true");
    this.loginService.saveMappedUserDetails(DATA)
    this.sceondDiv = false;
    this.tgAdminServiceObj.setHomeButtonStatus(headercompanyname);
    this.route.navigate(['/productpage']);
   // this.datepicker(companyID);
  //  this.loginService.vendorFinancialPeriodDatepicker(companyID);
    // below is eknath's code
    sessionStorage.removeItem('recon-vendor-wise-date')


  }

  // datepicker(event) {
  //   const url = "selectReturnMonth?&CompanyID=" + event;
  //   this.api.getMethod(url).subscribe(data => {
  //     this.localStorageObj.createLocalStorage("dates", JSON.stringify(data.response));
  //   })
  // }
  getUserMappingRecord(userSeqId) {
    this.roleMasterSeviceObj.getUserRoleMappingDetails('getUserCompanyMappingDetails?userId=' + userSeqId).subscribe(resp => {
      this.userRecord = resp.userMappingRecord;
      for (var i = 0; i < this.userRecord.length; i++) {
        if (this.userRecord[i].ho_gstin != null) {
          this.gstin_number = this.userRecord[i].ho_gstin;
          var stateCode = this.gstin_number.substring(0, 2)
          // this.state = this.stateCodeObj.getStateCode(stateCode);
          this.userRecord[i].state = this.state;
        }
      }
      var loginFromSupplierPortal = this.localStorageObj.getLocalStorage("loginFromSupplierPortal");
      console.log("loginFromSupplierPortal   ", loginFromSupplierPortal)
      if (loginFromSupplierPortal == "true") {
        this.selectRole(this.userRecord[0])
      }
    })
  }


  getMenus(tab) {
    this.localStorageObj.createLocalStorage("productkey", JSON.stringify(tab));
    this.route.navigate(['/products']);
  }

  passwordIcon1: boolean;
  passwordIcon2: boolean;
  passwordIcon3: boolean;

  togglePasswordVisibility1() {

    if (this.passwordIcon1 === true) {
      this.passwordIcon1 = false;
    } else {
      this.passwordIcon1 = true;
    }
  }

  togglePasswordVisibility2() {
    if (this.passwordIcon2 === true) {
      this.passwordIcon2 = false;
    } else {
      this.passwordIcon2 = true;
    }
  }

  togglePasswordVisibility3() {
    if (this.passwordIcon3 === true) {
      this.passwordIcon3 = false;
    } else {
      this.passwordIcon3 = true;
    }
  }

  chgpassvalid = false;
  errornmessage = true;
  errorcmessage = true;

  checkNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass) {
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass) {
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var newpas = e.target.value;

    if (this.changePassForm.value.currpass === newpas) {
      this.errornmessage = false;
    }
    else {

      this.errornmessage = true;
    }
  }

  checkCNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass) {
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass) {
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var checkcnewpas = e.target.value;

    if (this.changePassForm.value.newpass === checkcnewpas) {

      this.errorcmessage = true;
    } else {
      this.errorcmessage = false;
    }
  }

  onSubmitChangePassword() {

    const empID: any = this.localStorageObj.getLocalStorage("empID");
    const obj = {
      'oldPassword': this.changePassForm.value.currpass,
      'newPassword': this.changePassForm.value.newpass,
      'userID': empID
    };

    this.api.postMethod('changePassword', obj).subscribe(data => {

      if (data.Status === 'Success') {
        this.toaster.success('Password Changed Successfully');
        this.passwordSecurity.hide();
      } else {
        this.toaster.error(data.Reason);
        this.passwordSecurity.hide();
      }
    });
  }

}
