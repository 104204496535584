import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/auth/login.service';
import { LocalStorageService } from '../service/local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from '../service/http/api.service';

@Component({
  selector: 'app-expire-plan',
  templateUrl: './expire-plan.component.html',
  styleUrls: ['./expire-plan.component.css']
})
export class ExpirePlanComponent implements OnInit {

  constructor(private api: ApiService,private loginService: LoginService,private localStorageObj : LocalStorageService,private router : Router,) { 
    this.localStorageObj.createLocalStorage("expirePlan", "true");

  }

  ngOnInit() {
  }
  logout(){
    try {
      var object = {
        'userID': this.localStorageObj.getLocalStorage('empID') || '',
      }
      this.api.postMethod('newLogOutWeb' , object)
        .subscribe(response => {
          console.log('response Log Out ' + response)
        });
    }
    catch{ }
    sessionStorage.removeItem("ID");
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.router.navigate(['/login'])
    this.api.setsessionKey='';
    this.api.setEmpID='';
  }
}
