import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/auth/login.service';

@Component({
  selector: 'app-undermaintenance',
  templateUrl: './undermaintenance.component.html',
  styleUrls: ['./undermaintenance.component.css']
})
export class UndermaintenanceComponent implements OnInit {

  constructor(private loginService: LoginService,) { 
    this.loginService.setLoginResponse(false);
  }

  ngOnInit() {
  }

}
