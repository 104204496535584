import { Subscription, Subject } from 'rxjs';
import { CompanyService } from 'src/app/service/companylist/company.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../service/local-storage.service';
import { LoginService } from '../service/auth/login.service';
import { Router } from '@angular/router';
import { TgAdminService } from '../user-admin/formpages/Service/tg-admin.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../service/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('passwrodmodal')
  passwrodmodal: ModalDirective;
  @ViewChild('setMailModal')
  setMailModal: ModalDirective;

  responseMessage: any;
  companyName: any;
  lastLogin: any;
  chgpassvalid = false;
  // subscription:Subscription;

  onShow = true;
  changePassForm:  FormGroup;
  headerSubscription = new Subscription;
  WorkingCompany = new Subscription;
  gstin_number: String;
  company_name: String;
  state: String;
  mainDiv  = true;
  homeTag  = false;
  headerName = false;

  errornmessage = true;
  errorcmessage = true;
  productNavigationDiv = false;
  UserOrgType:any;
  setSpMailForm:FormGroup;

  constructor(private localStorageObj: LocalStorageService, private companyService: CompanyService,
              private loginService: LoginService, public route: Router, private tgAdminServiceObj: TgAdminService,
               private api: ApiService, private toaster: ToastrService) {


                this.changePassForm = new FormGroup({

                  currpass : new FormControl('', [Validators.required,
                            Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
                  newpass : new FormControl('', [Validators.required,
                            Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
                  confnewpass : new FormControl('',[Validators.required,
                            Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$@!%?&*#]).{8,12}$')]),
                });





    this.headerSubscription = this.tgAdminServiceObj.getHomeButtonStatus().subscribe(data => {
    this.company_name = data;
    });
        this.WorkingCompany = this.tgAdminServiceObj.getWorkingCompanyName().subscribe(resp => {
        this.company_name = localStorageObj.getLocalStorage('workingCompanyName');
        });



    localStorage.getItem('homeTagStatus');

    this.company_name = localStorageObj.getLocalStorage('company_name_for_header');

    var detail : any  = this.localStorageObj.getLocalStorage('detail');
    var data = JSON.parse(detail);

    this.lastLogin = data.loginTime;

    this.companyService.getHideProductNavigation().subscribe(data => {

      if (data === true) {
        this.productNavigationDiv = true;
      }
    });

    this.setSpMailForm = new FormGroup({
      email: new FormControl('', [Validators.email,Validators.required]),
      password: new FormControl('', Validators.required),
    })

  }

  ngOnInit() {
  }

  showpasswordmodal() {
    this.passwrodmodal.show();
  }

  closepasswordmodal() {
    this.passwrodmodal.hide();
  }

  logoutShow() {
    this.onShow = false;
  }

  logout() {
    try {
      var object = {
        'userID': this.localStorageObj.getLocalStorage('empID') || ''
      }
      this.api.postMethod('newLogOutWeb' , object)
        .subscribe(response => {
          console.log('response Log Out ' + response)
        });
    }
    catch{ }
    sessionStorage.removeItem("ID");
    sessionStorage.clear();
    localStorage.clear();
    this.loginService.setLoginResponse(false);
    this.route.navigate(['/login'])
    this.api.setsessionKey='';
    this.api.setEmpID='';
  }

  goHome() {
    this.route.navigate(['/mainlogin']);
    this.company_name = this.localStorageObj.getLocalStorage('company_name_for_header');
    this.homeTag = true;
  }


  onSubmitChangePassword() {

    const empID: any = this.localStorageObj.getLocalStorage("empID");
    const obj = {
      'oldPassword':this.changePassForm.value.currpass,
      'newPassword': this.changePassForm.value.newpass,
      'userID': empID
    };

    this.api.postMethod('changePassword',obj).subscribe(data => {

        if (data.Status === 'Success'){
           this.toaster.success('Password Changed Successfully');
           this.passwrodmodal.hide();
         }else{
          this.toaster.error(data.Reason);
          this.passwrodmodal.hide();
         }
       });
  }


  onlogoutClose() {
    this.onShow = true;
  }

  checkNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass){
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass){
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var newpas = e.target.value;

    if(this.changePassForm.value.currpass === newpas){
      this.errornmessage = false;
    }
    else{

      this.errornmessage = true;
    }

  }
  checkCNewPass(e) {

    if (this.changePassForm.value.currpass === this.changePassForm.value.newpass){
      this.chgpassvalid = false;
    } else if (this.changePassForm.value.newpass === this.changePassForm.value.confnewpass){
      this.chgpassvalid = true;
    } else {
      this.chgpassvalid = false;
    }

    var checkcnewpas = e.target.value;

    if (this.changePassForm.value.newpass === checkcnewpas) {

      this.errorcmessage = true;
    } else {
      this.errorcmessage = false;
    }
  }

  passwordIcon1: boolean;
  passwordIcon2: boolean;
  passwordIcon3: boolean;

  togglePasswordVisibility1() {

    if (this.passwordIcon1 === true) {
        this.passwordIcon1 = false;
     } else {
          this.passwordIcon1 = true;
      }

    }
    togglePasswordVisibility2() {
      if (this.passwordIcon2 === true) {
        this.passwordIcon2 = false;
          } else {
            this.passwordIcon2 = true;
        }
    }

    togglePasswordVisibility3() {
      if (this.passwordIcon3 === true) {
        this.passwordIcon3 = false;
        } else {
          this.passwordIcon3 = true;
        }
    }

    getSubMenu(menu) {

       this.companyService.setProductStatus(menu);

    }

    getDetailsOfLogin(){
     if(this.route.url === '/mainlogin'){return false}
     else{
      this.UserOrgType=null;
      var UserMappedRecord = JSON.parse(this.localStorageObj.getLocalStorage("UserMappedRecord"));
      this.UserOrgType=UserMappedRecord.org_type;
     }
    }

    hideSetSpMailDetails() {
      this.setMailModal.hide();
    }
    showSetSpMailDetails() {
      this.setMailModal.show();
      var loginDetails = JSON.parse(this.localStorageObj.getLocalStorage("detail"));
      var spEmailDetails ={
       'created_by':loginDetails.companyID
      }

      this.api.postMethod('getemailcredentials',spEmailDetails).subscribe(res => {
        if(res.getemailcredentials.length !== 0){
          this.setSpMailForm.patchValue({
            'email':res.getemailcredentials[0].from_email_id,
            'password':res.getemailcredentials[0].from_password
          })
        }
        else{
          this.setSpMailForm.patchValue({
            'email':'',
            'password':''
          })
        }
        
      });
    } 
    setSpMailMethod(data) {
      var loginDetails = JSON.parse(this.localStorageObj.getLocalStorage("detail"));
  
      var spEmailDetails = {
        'from_email_id': data.email,
        'from_password': data.password,
        'created_by': loginDetails.companyID,
        'template_name':loginDetails.companyname   
      }
      this.api.postMethod('setemailcredentials', spEmailDetails).subscribe(res => {
        if(res.status === 'Success'){
          this.toaster.success(res.reason);
          this.setMailModal.hide();
        }
        else{
          this.toaster.error(res.reason);
        }
      });
  
    }
    passwordIcon: boolean;
    togglePasswordVisibility() {
  
      if (this.passwordIcon === true) {
        this.passwordIcon = false;
      }
      else {
        this.passwordIcon = true;
      }
    }
}
