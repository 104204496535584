import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { ApiService } from 'src/app/service/http/api.service';
import { LoginService } from 'src/app/service/auth/login.service';
import { MenufooterService } from 'src/app/service/menufooter.service';
import { ProductService } from 'src/app/user-admin/service/product.service';
import { LocalStorage } from '@ng-idle/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit, OnDestroy {
  actionMenuList = [];
  lastLogin: any;
  lastLogin2: any;
  actionStatus: boolean = true;
  a: any;
  footerSub = new Subscription();
  constructor(private loginService: LoginService, private localStorage1: LocalStorageService, private api: ApiService,
    private productServiceObj: ProductService,
    public route: Router, private mfService: MenufooterService) {

    var detail: any = this.localStorage1.getLocalStorage('detail');
    var data = JSON.parse(detail);

    if (data != null) {
      this.lastLogin = data.loginTime;
      var loginDetails = JSON.parse(this.localStorage1.getLocalStorage('seqID'));
      this.productServiceObj.getUserName('UserName', loginDetails).subscribe(response => {
        this.a = response.name,
          this.lastLogin2 = this.a;
        localStorage.setItem('lastLoginUserName', this.lastLogin2);
        this.localStorage1.createLocalStorage('Username', this.a);
      });
    }

    this.footerSub = this.api.getMainFrameFooter().subscribe(data => {
      if (data) {
        this.actionStatus = false;
        let data: any = sessionStorage.getItem('actionData');
        data = JSON.parse(data);
        this.getActionMenu(data);
      } else {
        this.actionStatus = true;
      }
    });
  }

  ngOnInit() {
    this.loginService.setLoginResponse(false);
  }
  getActionMenu(sub) {
    
    console.log('Action Data => '+JSON.stringify(sub))
    if ((sub.levelId === 3 && sub.Action === 0) || (sub.levelId === 2 && sub.Action === 1)) {
      this.api.getMethod('getactionlist?roleId=' + this.localStorage1.getLocalStorage('roleId') +
        '&productId=' + sub.ProductId)
        .subscribe(data => {

          
          if (data.actionList.length > 0) {
            this.actionMenuList = data.actionList;
            this.actionStatus = false;

            if ((sub.Landing_Page == null || sub.Landing_Page == "")) {
              this.actionNav(this.actionMenuList[0])
              this.mfService.setfooter(this.actionMenuList);
            } else {
             // this.localStorage1.createLocalStorage('localStorage1',sub.ProductId)
              this.localStorage1.createLocalStorage('htmlPageId',JSON.stringify(sub.ProductId));
              this.route.navigate([sub.Landing_Page], { skipLocationChange: true });
            }

          } else {
            this.actionStatus = true;
            if (sub.levelId === 2) {
              console.log('*********> '+sub.ProductId)
             // this.localStorage1.createLocalStorage('localStorage1',JSON.stringify(sub.ProductId));
              this.localStorage1.createLocalStorage('htmlPageId',JSON.stringify(sub.ProductId));
              this.route.navigate([sub.Landing_Page], { skipLocationChange: true });
              
            }
          }
        })
    }
    else if ((sub.levelId === 3 && sub.Action === 1) || (sub.levelId === 2 && sub.Action === 1)) { 
     // this.localStorage1.createLocalStorage('localStorage1',sub.ProductId);
     this.localStorage1.createLocalStorage('htmlPageId',JSON.stringify(sub.ProductId));
      this.actionStatus = true; this.route.navigate([sub.Landing_Page], { skipLocationChange: true });
      
     }//++
  }

  actionNav(act) {
    console.log("action data => " + JSON.stringify(act))
    var actionParentId = act.ParentId;
    localStorage.setItem('ActiveAction', actionParentId);
    this.mfService.setFromFooter(actionParentId);
    console.log("action product ID  => " + JSON.stringify(act.ProductId))
    console.log("action product ID without json => " + act.ProductId);
    this.localStorage1.createLocalStorage('htmlPageId',JSON.stringify(act.ProductId));
    this.route.navigate([act.Landing_Page], { skipLocationChange: true });
  }

  getWidth() {
    let width = 98 / this.actionMenuList.length;
    return width + '%';
  }

  ngOnDestroy() {

    this.footerSub.unsubscribe();
  }
}
