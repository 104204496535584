import { HttpService } from './http.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private play = false;
  private pause = false;
  private stop = true;
  public playPauseStop$ = new EventEmitter();

  constructor(private http: HttpService) { }
  getAllProductList(url) {
    return this.http.getService(url);
  }
  getOrgTypeList(url) {
    return this.http.getService(url);

  }

  createProduct(url, model): Observable<any> {
    return this.http.postService(url, model);
   }

  addProductForSales(url, model): Observable<any> {
    return this.http.postService(url, model);
   }

   addTgProject(url, productDetails) {
    return this.http.postService(url, productDetails);
  }

   getProductList(url, parentID) {
    return this.http.getService(url + '?&parentID=' + parentID);
  }

  addMoreProducts(url, model): Observable<any> {
    return this.http.postService(url, model);
   }

  removeProducts(url, prodRecords): Observable<any> {

    return this.http.postService(url, prodRecords);
  }

  getRoleNameUnderSaleProduct(url, versionName): Observable<any> {

    return this.http.getService(url + '?versionName=' + versionName);

  }

//  _______________________Project Management__________________________________________________

  getAllProjectList(url): Observable<any> {
    return this.http.getService(url);
  }
  deleteData(url, name, Id, levelId): Observable<any> {
    return this.http.getService(url + '?&s_Name=' + name + '&Id=' + Id + '&levelId=' + levelId);
  }
  updateData(url, abc, Id, levelId, editedpro, editdesp): Observable<any> {
    return this.http.getService(url + '?&s_Name=' + abc + '&Id=' + Id + '&levelId=' + levelId + '&newCat=' + editedpro + '&desp=' +
    editdesp);
  }


  editData(url, abc,pro_name,Id,  levelId, editstart, editend, Employee, t_time, Role): Observable<any> {
    return this.http.getService(url + '&s_Name=' + abc + '&pro_name=' + pro_name + '&Id=' + Id + '&levelId=' + levelId +
    '&startDate=' + editstart + '&endDate=' + editend + '&Employee=' + Employee + '&t_time=' + t_time + '&Role=' + Role);
  }
  getProjectList(url, n_Parent_Id): Observable<any> {
    return this.http.getService(url + '&n_Parent_Id=' + n_Parent_Id);
  }
  getUserProject(url, userName): Observable<any> {
    return this.http.getService(url + '&userName=' + userName);
  }

  getUserName(url, userID): Observable<any> {
    return this.http.getService(url + '?&userid=' + userID);
  }

  getMethod(url: any): Observable<any> {

    return this.http.getService(url);
  }


  saveStartDate(url, n_Sr_No, status, startDate) {
    return this.http.getService(url + '?&n_Sr_No=' + n_Sr_No + '&n_Status=' + status + '&startDate=' + startDate);
  }

  // 'TaskHistory',this.data.n_Sr_No,this.data.n_Seq_Id,this.localstorage.getLocalStorage('Username'),
  // this.remark,sdate,dayName,syear
  saveDur(url, n_Sr_No, n_Seq_Id, s_Name, username, remark, sdate, dayName, syear, duration) {
    return this.http.getService(url + '?n_sr_no=' + n_Sr_No + '&n_seq_id=' + n_Seq_Id + '&s_task_name=' + s_Name +
    '&s_employee=' + username + '&s_remark=' + remark + '&d_date=' + sdate + '&s_day=' + dayName + '&s_year=' + syear +
    '&t_task_duration=' + duration
    );
  }

  saveDur2(url, duration2, n_Sr_No) {
    return this.http.getService(url + '?dura=' + duration2 + '&n_Sr_No=' + n_Sr_No);
  }


  saveEndDate(url, n_Sr_No, endDate, estatus) {
    return this.http.getService(url + '?&n_Sr_No=' + n_Sr_No + '&endDate=' + endDate + '&n_Status=' + estatus);
  }
  public playTimer() {
    this.play = true;
    this.pause = false;
    this.stop = false;

    this.playPauseStop$.emit({
        play: this.play
    });
  }

  public pauseTimer() {
    this.play = false;
    this.pause = true;
    this.stop = false;

    this.playPauseStop$.emit({
        pause: this.pause
    });
  }

  public stopTimer() {
    this.play = false;
    this.pause = false;
    this.stop = true;

    this.playPauseStop$.emit({
        stop: this.stop
    });
  }
}
