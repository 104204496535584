import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {}
  createLocalStorage(key: string, value: any){
    
  console.log('value => '+value);
  if(key=='selectedYear'){
 localStorage.setItem('finYear',value);
  }
    var cryptoData : any = CryptoJS.AES.encrypt(value, 'encoded data from taxgenie portal');
    sessionStorage.setItem(key,cryptoData);
  }

  getLocalStorage(key){
    var encodeData = sessionStorage.getItem(key);
    if (encodeData) {
      var decodeData = CryptoJS.AES.decrypt(encodeData, 'encoded data from taxgenie portal').toString(CryptoJS.enc.Utf8);
      return decodeData;
    } else {
      return null;
    }
  }
}
