import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TgAdminService {

  public homeButtonStatus = new Subject<any>();
  public workingCompanyStatus = new Subject<any>();
  
  constructor() { }

  

  setHomeButtonStatus(Status: boolean) {
    this.homeButtonStatus.next(Status);
    
  }
  getHomeButtonStatus(): Observable<any> {
    return this.homeButtonStatus.asObservable();
  }

  setWorkingCompanyName() {
    this.workingCompanyStatus.next();
    
  }
  getWorkingCompanyName(): Observable<any> {
    return this.workingCompanyStatus.asObservable();
  }
}
