import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate  } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../service/local-storage.service';
import { LoginService } from '../service/auth/login.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor( private crypto :LocalStorageService, private route: Router, private loginService: LoginService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authUser : any = this.crypto.getLocalStorage('ID');
    if(authUser != null){
      this.loginService.setLoginResponse(true);
    }else{
      this.loginService.setLoginResponse(false);

      this.route.navigate(['/login']);
    }
    return true;
  }
}
