import { Injectable } from '@angular/core';
// import * as Excel from "exceljs/dist/exceljs.min.js";
// import * as ExcelProper from 'ExcelJS';
// import * as FileSaver from 'file-saver';
declare const ExcelJS:any;
@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }

  exportExcel(sheetName, excelName, column, sheetColumnskey, columnIndex, data, string?) {

    let sname: string = sheetName;
    let blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelFileName: string = excelName;
    let cols = column;
    let workbook = new ExcelJS.Workbook();

    workbook.addWorksheet(sname, { views: [{ state: 'frozen', activeCell: 'A1', ySplit: 1, xSplit: 1 }] },
    {
      properties: [{ showGridLines: true }]
    })

    var sheet = workbook.getWorksheet(columnIndex);
    sheet.addRow(cols);
    sheet.columns = sheetColumnskey;
    let colArray = ['A1', 'B1', 'C1', 'D1', 'E1'];

    data.forEach(d => {
      let row = sheet.addRow(d);
    });

    sheet.eachRow(function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        if (cell.value === string)
          row.getCell(colNumber).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'C6EFCE' }
          };
      });
    });

    workbook.xlsx.writeBuffer().then(data => {
      var blob = new Blob([data], { type: blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();

    });

  }

  exportExcelAllRecon(sheetName, excelName, column, sheetColumnskey, columnIndex, data) {
    let sname: string = sheetName;
    let blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelFileName: string = excelName;
    let cols = column;
    let workbook = new ExcelJS.Workbook();

    workbook.addWorksheet(sname, { views: [{ state: 'frozen', activeCell: 'A1', ySplit: 1, xSplit: 1 }] },
      {
        properties: [{ showGridLines: true }]
      })

    var sheet = workbook.getWorksheet(columnIndex);
    sheet.addRow(cols);
    sheet.columns = sheetColumnskey
    let colArray = ['A1', 'B1', 'C1', 'D1', 'E1'];
    data.forEach(d => {
      let row = sheet.addRow(d);
      let type = row.getCell(31);
      let color = 'c6efce'; //GreenColor
      let misColor = 'd99795'; //pinkishRed
      let invMisColor = 'd9d9d9'; //veryLightGrey
      let g2aColor = 'c6cfef'; //LightPurple
      let prColor = 'FFFFFF';
      if (type == 'MISMATCHED') { color = misColor };
      if (type == '2A-UNMATCHED' || type == 'G2AReject') { color = g2aColor };
      if (type == 'INV_MISMAT') { color = invMisColor };
      if (type == 'PR-UNMATCHED' || type == 'PRAccept') { color = prColor };
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' }
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      var blob = new Blob([data], { type: blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();
    });
  }



  exportExcelAll(sheetName, excelName, column, sheetColumnskey, columnIndex, data, string?) {
    let sname: string = sheetName;
    let blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelFileName: string = excelName;
    let cols = column;
    let workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(sname, { views: [{ state: 'frozen', activeCell: 'A1', ySplit: 1, xSplit: 1 }] },
      {
        properties: [{ showGridLines: true }]
      })

    var sheet = workbook.getWorksheet(columnIndex);
    sheet.addRow(cols);
    sheet.columns = sheetColumnskey
    let colArray = ['A1', 'B1', 'C1', 'D1', 'E1'];

    data.forEach(d => {
      let row = sheet.addRow(d);
    });

  sheet.eachRow(function (row, rowNumber)
     {
          row.eachCell(function (cell, colNumber)
          {
            if (cell.value === string)
              row.getCell(colNumber).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C6EFCE' }
              };
           }
         );
    });


    workbook.xlsx.writeBuffer().then(data => {
      var blob = new Blob([data], { type: blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();
    });
  }


  exportExcelWithHeader(sheetName, excelName, column, sheetColumnskey, columnIndex, data, string?, headername?, companyname?, date?) {
    let sname: string = sheetName;
    let blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let excelFileName: string = excelName;
    let cols = column;
    let workbook = new ExcelJS.Workbook();
    // workbook.creator = 'Me';
    // workbook.lastModifiedBy = 'Her';
    // workbook.created = new Date();
    // workbook.modified = new Date();
    workbook.addWorksheet(sname, { views: [{ySplit: 1, xSplit: 1 }] },
      {
        properties: [{ showGridLines: true }]
      })

    var sheet = workbook.getWorksheet(columnIndex);
    sheet.addRow([headername]);
    sheet.addRow([companyname]);
    sheet.addRow([date]);
    sheet.addRow([]);
    sheet.addRow(cols);
    sheet.columns = sheetColumnskey
    let colArray = ['A1', 'B1', 'C1', 'D1', 'E1'];

    data.forEach(d => {
      let row = sheet.addRow(d);
    });

  sheet.eachRow(function (row, rowNumber)
     {
          row.eachCell(function (cell, colNumber)
          {
            if (cell.value === string)
              row.getCell(colNumber).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C6EFCE' }
              };
           }
         );
    });


    workbook.xlsx.writeBuffer().then(data => {
      var blob = new Blob([data], { type: blobType });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = excelFileName;
      a.click();
    });
  }

}
