import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { LocalStorageService } from '../local-storage.service';
import { Subject, Observable} from 'rxjs';

@Injectable ({
  providedIn: 'root'
})
export class CompanyService {
  subject = new Subject<any>();
  productStatus = new Subject<any>();
  productNavigationStatus = new Subject<any>();
  constructor(private api: ApiService, private localStorage: LocalStorageService) { }

  getAllRecords() {
    let loginDetails: any = this.localStorage.getLocalStorage('detail');
    loginDetails = JSON.parse(loginDetails);
    const url = 'getAllCACompany?companyID=' + loginDetails.companyID + '&userID=' + loginDetails.userID;
    return this.api.getMethod(url);
  }
  getAllClient(data) {
    const userId: any = this.localStorage.getLocalStorage('ID');
    const url = 'getAllClient?companyID=' + data.companyID + '&userID=' + userId;
    return this.api.getMethod(url);
  }
  setCompanyApiType(type: any) {
    this.subject.next(type);
  }
  getCompanyApiType(): Observable<any> {
    return this.subject.asObservable();
  }

  setProductStatus(type: any) {
    this.productStatus.next(type);
  }
  getProductStatus(): Observable<any> {
    return this.productStatus.asObservable();
  }

  setHideProductNavigation(type: any) {
    this.productNavigationStatus.next(type);
  }
  getHideProductNavigation(): Observable<any> {
    return this.productNavigationStatus.asObservable();
  }

  getService(url){
    return this.api.getMethod(url);
  }
}
