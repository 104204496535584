import { LocalStorageService } from './../../service/local-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  modelObj:any;
  URL= environment.baseUrl;
  URL_PYTHON= environment.baseUrl1;
  httpOptions={
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'empID': this.localStorge.getLocalStorage("empID")||''
    })
  };
  constructor(private http: HttpClient, private localStorge : LocalStorageService) { }
  getService(url): Observable<any>{
     let urlpath = this.URL.concat(url);

     return this.http.get<any>(urlpath).pipe(share());
   }
   getServicewithheader(url): Observable<any>{
    let urlpath = this.URL.concat(url);

    return this.http.get<any>(urlpath,this.httpOptions).pipe(share());  
  }

 postServiceLogin(url,model): Observable<any>{

    this.modelObj = {
      "username":model.username
    };

    let httpOptions = {
         headers: new HttpHeaders({
         'password':model.password
       })
   };

   let urlpath = this.URL.concat(url);
   return this.http.post<any>(urlpath,this.modelObj,httpOptions).pipe(share());

 }

  postService(url, model): Observable<any>{

    console.log(" empId 12 => "+this.localStorge.getLocalStorage("empID"));
    let urlpath = this.URL.concat(url);
   // let urlpath='//192.168.1.32:8080/gstsvrc-0.0.1/'+url
    return this.http.post<any>(urlpath,model,this.httpOptions).pipe(share());
}
postServiceForParam(url): Observable<any>{

  let urlpath = this.URL.concat(url);
 // let urlpath='//192.168.1.32:8080/gstsvrc-0.0.1/'+url
  return this.http.post<any>(urlpath,this.httpOptions).pipe(share());
}
   //put service for update API
   putService(url,model): Observable<any>{
    console.log(" empId 12 => "+this.localStorge.getLocalStorage("empID"));
    let urlpath = this.URL.concat(url);
    return this.http.put<any>(urlpath,model,this.httpOptions).pipe(share());
   }

   postService1(url): Observable<any>{
     
       let urlpath = this.URL.concat(url);
       return this.http.post<any>(urlpath,this.httpOptions).pipe(share());
}
  getServiceForPython(url): Observable<any>{
    let urlpath = this.URL_PYTHON.concat(url);
    return this.http.get<any>(urlpath).pipe(share());
  }
}
