import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultRoleMasterService {

  constructor(private http:HttpService) {

   }

  getOrgList1(url):Observable<any>{

    return this.http.getService(url);

  }
  getOrgTypeList(url):Observable<any>{

    return this.http.getService(url);

  }

  getListOfCompanyByType(url):Observable<any>{

    return this.http.getService(url);

  }
  getListOfCompanyUnderTG(url):Observable<any>{

    return this.http.getService(url);

  }

  getSaleProductList(url):Observable<any>{

    return this.http.getService(url);

  }

  getVersionName(url):Observable<any>{

    return this.http.getService(url);
  }

  addTgDefaultRole(url,defaultProductDetails):Observable<any>{

    return this.http.postService(url,defaultProductDetails);
  }

  getRoleList(url):Observable<any>{

    return this.http.getService(url);
  }

  checkRoleNameRegistered(url):Observable<any>{

    return this.http.getService(url);
    
  }

  getUserRoleMappingDetails(url):Observable<any>{

    return this.http.getService(url);

  }
  getAddControls(url){
    return this.http.getService(url);    
  }

  addMoreProductsInsideDefaultRole(url,defaultProductList):Observable<any>{

    return this.http.postService(url,defaultProductList);
  }

  deleteProductFromDefaultRole(url,defaultProductList):Observable<any>{

    return this.http.postService(url,defaultProductList);
    
  }
}
