import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareProductService {

  
  private shareProductData = new Subject<any>();

  constructor() { }

  setProductMenu(data: any) {
   
    this.shareProductData.next({ data });
  }
  getProductMenu(): Observable<any> {
    return this.shareProductData.asObservable();
  }

}
